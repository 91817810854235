.canvas-container {
	position:relative;
	width:100%;
	height: calc(100vh - (80px + 85px));
    margin-top: 85px;
    display:flex;
    align-items: flex-end;
    justify-content: center;

    @include screen-tablet-landscape-and-bigger {
        margin-top: 100px;
        min-height:400px;
        height: calc(100vh - (100px + 38px + 26px));
    }

	@include screen-laptop-and-bigger {
		transition: width .8s $ease-out-quart;
	}

	.menu--is-active & {
		@include screen-tablet-landscape-and-bigger {
            width: calc(50vw - 35px);
            transition: width .8s $ease-out-quart;
		}
	}

	// .general & {
    //     height: calc(100vh - (100px + 5px));
	// 	margin-bottom: 20px;
	// 	@include screen-tablet-landscape-and-bigger {
	// 		height: calc(100vh - (100px + 18px));
	// 	}
	// }


	.canvas-container__image {
		width:100%;
        height:100%;
        color:$color-grey;
        display:flex;
        align-items: center;
        justify-content: center;

		img {
			width:100%;
			height:100%;
		}
	}

	&.canvas-container--half {
		height: calc(65vh - (50px + 10px + 30px));
    }
}

.canvas-container--bg {
	position:fixed;
	top:15px;
	right:15px;
	bottom:15px;
	left:15px;

	.menu--is-active & {
		@include screen-tablet-landscape-and-bigger {
			right:calc(50vw + 15px);
		}
	}
}

.canvas-container--is-fixed {
	position: fixed;
	top: 15px;
	width: calc(100% - 30px);
}

.canvas-container--is-absolute {
	position: absolute;
	top: 15px;
	width: calc(100% - 30px);
}


