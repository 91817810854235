.content {
  &.curators {
    h3 {
      margin: $gutter-vertical-micro*3 auto 0 auto;
      @include screen-tablet-landscape-and-bigger {
        margin: $gutter-vertical-regular auto 0 auto;
      }
      width: min-content;
    }
  }
  .person-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $gutter-vertical-bigger;
    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gutter-vertical-huge;
    }
    .person-container-text {
      text-align: center;
      h3 {
        display: flex;
        justify-content: center;
        width: 100%;
        @include text-style-desktop-h-3-medium;
        text-align: center;
      }
      p {
        text-align: center;
        @include text-style-desktop-m-medium;
        & + p {
          margin-top: $gutter-vertical-micro;
        }
      }
      > * {
        margin-top: $gutter-vertical-small;
        @include screen-tablet-landscape-and-bigger {
          margin-top: $gutter-vertical-regular;
        }
      }
      span.caption {
        & + .caption {
          margin-top: 0;
        }
      }
      div {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        button {
          margin: 0;
          width: 100%;
          a {
            width: 100%;
          }
        }
      }
    }
    .person-container-image {
      img {
        background-color: $color-darkblue;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .menu--is-active & {
      max-width: 100%;
      flex-basis: 100%;
      @include screen-tablet-landscape-and-bigger {
        flex-basis: calc(100% - 19%);
      }
      left: 9%;
    }
  }
}