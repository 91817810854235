.content {
    .three-column-wrapper {
        display: flex;
        justify-content: center;
        flex-flow: wrap;
        justify-content: center;
        column-gap: $gutter-vertical-micro*3;
        @include screen-tablet-landscape-and-bigger {
            column-gap: $gutter-vertical-bigger;
        }
        .three-column-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-decoration: none;
            flex: 0 1 calc(50% - 15px);
            margin-bottom: $gutter-vertical-micro*4;
            @include screen-tablet-landscape-and-bigger {
                flex: 0 1 27.5%;
                margin-bottom: $gutter-vertical-micro*10;
            }
            .three-column-image {
                flex-basis: auto;
                width: 100%;
                transition: transform $interaction-timing $ease-out-quart;
                transform-origin: bottom;
            }
            .three-column-text {
                display: flex;
                flex-direction: column;
                text-align: center;
                transition: color $interaction-timing $ease-out-quart;
                margin-top: $gutter-vertical-micro*2;
                @include screen-tablet-landscape-and-bigger {
                    margin-top: $gutter-vertical-regular;
                }
                
                svg {
                    transition: transform $interaction-timing $ease-out-quart;
                    margin-left: $gutter-vertical-micro*.5;
                    width: 15px;
                    @include screen-tablet-landscape-and-bigger {
                        width: auto;
                    }
                }
                h3 {
                    @include text-style-desktop-l-medium;
                    margin-bottom: 0;
                    @include screen-tablet-landscape-and-bigger {
                        margin-bottom: $gutter-vertical-regular;
                    }
                }
                &.curator-caption {
                    h3 {
                      margin-bottom: 0;  
                    }
                    path {
                        .theme-darkred & {
                            fill: $color-red;
                        }
                        .theme-darkyellow & {
                            fill: $color-yellow;
                        }
                    }
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &:hover {
                h3, span {
                    color: $color-grey;
                }
                svg {
                    transform: translate($gutter-vertical-micro);
                    path {
                        fill: $color-grey;
                    }
                }
                .three-column-image {
                  transform: scale(1.01);
                }
                .three-column-text {
                    &.curator-caption {
                        path {
                            fill: $color-grey;
                        }
                    }
                }
            }
            .menu--is-active & {
                flex-direction: column;
                .three-column-image {
                    display: contents;
                    max-width: 100%;
                    flex-basis: 100%;
                    img {
                        margin-bottom: $gutter-vertical-regular;
                    }
                }
                .three-column-text {
                    max-width: 100%;
                    flex-basis: 100%;
                    left: 0;
                }
                @include screen-tablet-landscape-and-bigger {
                    flex-basis: calc(100% - 19%);
                }
                left: 9%;
            }
        }
    }
}

.news-single {
    margin: $gutter-vertical-huge 0;
    .page-header {
        margin-top: 0;
    }
    .caption {
        margin-top: $gutter-vertical-regular;
    }
    .news-image {
        width: 75%;
        margin: 0 auto $gutter-vertical-micro*4 auto;
        @include screen-tablet-landscape-and-bigger {
            width: 50%;
            margin: 0 auto $gutter-vertical-bigger auto;
        }
        @include screen-laptop-and-bigger {
            width: 40%;
            margin: 0 auto $gutter-vertical-micro*10 auto;
        }
        img {
            background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
            height: 100%;
        }
    }
    .news-description {
        color: $color-off-white;
        text-align: center;
        @include text-style-desktop-xl-medium;
        margin-bottom: $gutter-vertical-big;
        @include screen-tablet-landscape-and-bigger {
            margin-bottom: $gutter-vertical-biggest;
        }
        .buttons-wrapper {
            display: flex;
            justify-content: center;
            flex-flow: wrap;
            margin-top: $gutter-vertical-bigger;
            column-gap: $gutter-vertical-micro;
            row-gap: $gutter-vertical-micro;
            .primary-button {
                margin: initial;
            }
        }
    }
}

// .content {
//     .news-container {
//         display: flex;
//         align-items: center;
//         flex-direction: column;
//         @include screen-tablet-landscape-and-bigger {
//             flex-direction: row;
//         }
//         margin-bottom: $gutter-vertical-bigger;
//         .news-container-image {
//             flex-basis: auto;
//             width: 100%;
//         }
//         .news-container-text {
//             display: flex;
//             flex-direction: column;
//             h3 {
//                 text-align: left;
//                 margin-bottom: $gutter-vertical-micro*3;
//                 margin-top: $gutter-vertical-micro*3;
//                 @include text-style-desktop-xl-medium;
//                 @include screen-tablet-landscape-and-bigger {
//                     margin-top: 0;
//                     margin-bottom: $gutter-vertical-regular;
//                 }
//             }
//             p {
//                 & + p {
//                     margin-top: $gutter-vertical-micro;
//                 }
//             }
//             @include text-style-desktop-m-medium;
//         }
//         &:last-of-type {
//             margin-bottom: 0;
//         }
//         .menu--is-active & {
//             flex-direction: column;
//             .news-container-image {
//                 display: contents;
//                 max-width: 100%;
//                 flex-basis: 100%;
//                 img {
//                     margin-bottom: $gutter-vertical-regular;
//                 }
//             }
//             .news-container-text {
//                 max-width: 100%;
//                 flex-basis: 100%;
//                 left: 0;
//             }
//             @include screen-tablet-landscape-and-bigger {
//                 flex-basis: calc(100% - 19%);
//             }
//             left: 9%;
//         }
//     }
// }