.contact-wrapper {
  > .content {
    margin-bottom: $gutter-vertical-bigger;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gutter-vertical-huge;
    }

    &:last-of-type {
      margin-bottom: $gutter-vertical-huge;
    }
    .primary-button {
      margin-top: $gutter-vertical-small*1.2;
      @include screen-tablet-landscape-and-bigger {
        margin-top: $gutter-vertical-micro*3;
      }
    }
  }
}