.theme-yellow {
  #cookie-bar {
	background: $color-darkblue !important;
	font-family: $font-stack-primary !important;
	p {
	  color: $color-white;
	  a {
		color: $color-grey;
		&:hover {
		  color: $color-white;
		}
	  }
	}
	#cookie-bar-button {
	  color: $color-white !important;
	  &:hover {
		color: $color-grey !important;
		border-color: $color-grey !important;
	  }
	}
	#cookie-bar-button-no {
	  color: $color-grey !important;
	}
  }
}

.theme-2025{
	#cookie-bar {
		background: $color-black !important;
		font-family: $font-stack-primary !important;
		p {
		  color: $color-white;
		  a {
			color: $color-grey;
			&:hover {
			  color: $color-white;
			}
		  }
		}
		#cookie-bar-button {
		  color: $color-white !important;
		  &:hover {
			color: $color-grey !important;
			border-color: $color-grey !important;
		  }
		}
		#cookie-bar-button-no {
		  color: $color-grey !important;
		}
	  }
}

.theme-black {
  #cookie-bar {
	background: $color-black !important;
	font-family: $font-stack-primary !important;
	p {
	  color: $color-white;
	  a {
		color: $color-grey;
		&:hover {
		  color: $color-white;
		}
	  }
	}
	#cookie-bar-button {
	  color: $color-white !important;
	  &:hover {
		color: $color-grey !important;
		border-color: $color-grey !important;
	  }
	}
	#cookie-bar-button-no {
	  color: $color-grey !important;
	}
  }
}

.theme-darkblue {
  #cookie-bar {
	background: $color-grey !important;
	font-family: $font-stack-primary !important;
	p {
	  color: $color-yellow;
	  a {
		color: $color-darkblue;
		&:hover {
		  color: $color-yellow;
		}
	  }
	}
	#cookie-bar-button {
	  color: $color-yellow !important;
	  &:hover {
		color: $color-darkblue !important;
		border-color: $color-darkblue !important;
	  }
	}
	#cookie-bar-button-no {
	  color: $color-darkblue !important;
	}
  }
}

#cookie-bar {
  padding: $gutter-vertical-micro*1.6 $gutter-vertical-small !important;
  @include screen-tablet-landscape-and-bigger {
	align-items: center;
	flex-direction: row;
  }
  p {
	font-family: $font-stack-primary !important;
	color: $color-white !important;
	margin: 0 !important;
	width: 100%;
	@include screen-tablet-landscape-and-bigger {
	  width: 25%;
	}
	@include text-style-desktop-s-medium;
	a {
	  display: contents !important;
	}
  }
  #cookie-bar-button-no {
	background: transparent !important;
	border-radius: 0 !important;
	border: none !important;
	text-shadow: none !important;
	color: $color-grey !important;
	text-decoration: none !important;
	padding: 9px 0px;
	margin: 8px auto;
	width: 100%;
	text-align: center;
	@include screen-tablet-landscape-and-bigger {
	  padding: 6px 0px;
	  width: auto;
	  margin: 8px auto 8px 30px;
	}
	@include text-style-desktop-s-medium;
	&:hover {
	  color: $color-white !important;
	}
  }
  #cookie-bar-button {
	border-radius: 6px !important;
	background: transparent !important;
	border: 2px solid !important;
	border-color: $color-white !important;
	text-shadow: none !important;
	text-decoration: none !important;
	margin: 0 0 0 0;
	text-align: center;
	padding: $gutter-vertical-micro*1.2 $gutter-vertical-regular;
	height: 43px !important;
	width: 100%;
	@include screen-tablet-landscape-and-bigger {
	  width: auto;
	}
	@include text-style-desktop-s-medium;
	&:hover {
	  color: $color-yellow !important;
	}
  }
}

#cookie-bar #cookie-bar-prompt-button{
  display: none !important;
}