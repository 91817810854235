.menu-link {
  cursor: pointer;
  position: fixed;
  right: $gutter-vertical-small;
  top: 32px;
  z-index: 2;
  transition: right $color-transition-timing $ease-out-quart, color $color-transition-timing $ease-out-quart;
  pointer-events: all;
  @include text-style-desktop-l-medium;
  
  @include screen-tablet-landscape-and-bigger {
    top: 32px;
    right: $gutter-vertical-small;
    
    &:hover {
      cursor: pointer;
      color: $color-yellow;
    }
  }  
  span {
    display: inline;
    text-transform: none;
    pointer-events: all;
  }
  svg {
    display: inline-block;

    @include screen-tablet-landscape-and-bigger {
      display: none;
    }
  }
}

.back-link {
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: $gutter-vertical-small;
  @include screen-tablet-landscape-and-bigger {
    top: 35px;
    left: 25px;
  }
  z-index: 2;
  pointer-events: all;
  display: flex;
  a {
    display: flex;
    svg {
      display: flex;
      width: $gutter-vertical-regular;
      @include screen-tablet-landscape-and-bigger {
        width: unset;
      }
      path {
        transition: fill $color-transition-timing $ease-out-quart;
      }
    }
    &:hover {
      svg {
        path {
          fill: $color-white
        }
      }
    }
  }
}

header {
  padding: $gutter-vertical-small 0;
  z-index: 1000;
  position: absolute;
  width: 100%;
  pointer-events: none;
  
  .o-container:last-child{
    margin-bottom: 0;
  }
  
  a {
    color: inherit;
  }
  
  nav {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: $color-grey;
    width: 100%;
    transition: width .4s $ease-out-quart;
    
    .menu--is-active & {
      width: 100%;
      @include screen-tablet-landscape-and-bigger {
        width: 50%;
      }
    }
    
    .header__logo {
      hyphens: none;
      @include text-style-desktop-m-super;
      max-width: 80%;
      text-align: center;
      pointer-events: all;

      @include screen-tablet-landscape-and-bigger {
        &:hover {
          color: $color-yellow;
        }
      }
    }

    .demoverse__overlay {
      position: absolute;
      display: none;
      width: 18vw;
      top: -10px;
      left: -20px;
      height: auto;
      pointer-events: all;
      transition: transform $interaction-timing $ease-out-quart;
      transform-origin: left; 
      &:hover {
        transform: scale(1.05);
      }
      @include screen-tablet-landscape-and-bigger {
        display: flex;
      }
    }

    .menu-close {
      position: absolute;
      display: flex;
      top: 32px;
      right: $gutter-vertical-micro*3;
      font-weight: $font-weight-medium;
      text-transform: none;
      cursor: pointer;
      @include screen-tablet-landscape-and-bigger {
        top: 35px;
        right: 30px;
      }
      svg {
        width: 44px;
        @include screen-tablet-landscape-and-bigger {
          width: auto;
        }
        path {
          transition: fill $color-transition-timing linear;
          fill: $color-black;
        }
      }
    }

    .menu {
      position: fixed;
      font-weight: $font-weight-super;
      width: 100vw;
      height: 100vh;
      right: 0;
      top: 0;
      font-size: $font-size-small;
      list-style: none;
      padding: $gutter-vertical-small 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translate3d(100%, 0, 0);
      z-index: 3;
      pointer-events: all;

      .page--is-loaded & {
        transition: transform $interaction-timing $ease-out-quart, background-color $color-transition-timing linear;
      }

      @include screen-tablet-landscape-and-bigger {
        width: 50%;
        padding: $gutter-vertical-small 0;
      }

      .menu--is-active & {
        transform: translate3d(0, 0, 0);
      }
      .secondary-menu-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: $gutter-vertical-micro;
        position: absolute;
        bottom: $gutter-vertical-micro*6;
        text-transform: capitalize;
        span {
          text-align: center;
          color: $color-black;
          @include text-style-desktop-s-medium;
        }
      }
      ul {
        width: 100%;
        li {
          width: 100%;
          font-weight: $font-weight-super;
          display: block;
          line-height: 1.1;
          text-align: center;
          
          @include screen-tablet-landscape-and-bigger {
            margin-bottom: 0;
          }
          
          &.link--external {
            font-weight: $font-weight-medium;
          }
        }
		
        &.primary-menu {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @include text-style-desktop-h-3-super;
          letter-spacing: -0.025em;
          @include screen-tablet-landscape-and-bigger {
            @include text-style-desktop-h-3-super;
          }
          li {
            line-height: 1.1;
          }
        }

        &.secondary-menu {
          text-transform: capitalize;
          display: flex;
          flex-wrap: wrap;
          align-self: center;
          height: auto;
          justify-content: center;
          flex-direction: column;
          row-gap: $gutter-vertical-micro/4;
          align-items: center;
          li {
            @include text-style-desktop-l-medium;
            white-space: pre;
            max-width: min-content;
            a {
              &:hover{
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

.whole-page {
  width: 100%;
  margin-left: 0;

  .page--is-loaded & {
    transition: width .4s $ease-out-quart;
  }

  .menu--is-active & {
    @include screen-tablet-landscape-and-bigger {
      width: 50%;
      margin-left: 0;
    }
  }
  &.no-margin {
    padding-left: 0;
    padding-right: 0;
  }
  &.small-bottom-margin {
    margin-bottom: $gutter-vertical-big;
  }
  &.regular-bottom-margin {
    margin-bottom: $gutter-vertical-regular;
  }
  &.big-bottom-margin {
    margin-bottom: $gutter-vertical-big;
    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $gutter-vertical-biggest;
    }
  }
  &.no-mobile-margin {
    padding-left: 0;
    padding-right: 0;
    @include screen-tablet-landscape-and-bigger {
      padding-left: $gutter-vertical-small ;
      padding-right: $gutter-vertical-small ;
    }
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
}

.menu--is-active .whole-page {
  @include screen-tablet-landscape-and-bigger {
    .o-container {
      padding-right: $gutter-vertical-small;
    }

    .push-me-in {
      right: 0;
      flex-basis: 100%;
      max-width: 100%;
    }

    .partner {
      .logo {
        flex-basis: 60%;
        max-width: 60%;
        margin: 0 20%;
      }

      .description {
        display: none;
      }
    }
  }
}

.submit-page-container{
	margin-top:100px;
}

.hoverMeGrey:hover{
	filter: invert(75%) sepia(1%) saturate(0%) hue-rotate(82deg) brightness(105%) contrast(87%);
	img{
		filter: invert(0%) sepia(1%) saturate(0%) hue-rotate(82deg) brightness(150%) contrast(107%);
	}
}
