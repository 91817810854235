@mixin button--base {
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  vertical-align: top;
  margin: 0;
  text-align: center;
}

button {
  @include button--base;
}

.lead-BGImage-huge, .blue-section, .megascreen-info, .carousel, .theme-darkblue, .lead-BGImage {
  .primary-button {
    border-color: $color-yellow;
    a,span {
      color: $color-yellow;
    }
    &:hover {
      background-color: $color-yellow;
      a,span {
        color: $color-darkblue;
      }
    }
  }
}

.theme-2025 {
	.primary-button {
	  border-color: $color-off-white;
	  a,span {
		color: $color-off-white;
	  }
	  &:hover {
		background-color: $color-off-white;
		a,span {
		  color: $color-darkblue;
		}
	  }
	}
  }

.theme-black {
  .primary-button {
    border-color: $color-white;
    a,span {
      color: $color-white;
    }
    &:hover {
      background-color: $color-white;
      a,span {
        color: $color-black;
      }
    }
  }
}

.theme-darkred {
  .primary-button {
    border-color: $color-red;
    a,span {
      color: $color-red;
    }
    &:hover {
      background-color: $color-red;
      a,span {
        color: $color-darkblue;
      }
    }
  }
}

.theme-darkyellow {
  .primary-button {
    border-color: $color-yellow;
    a,span {
      color: $color-yellow;
    }
    &:hover {
      background-color: $color-yellow;
      a,span {
        color: $color-darkblue;
      }
    }
  }
}

.theme-red {
  .primary-button {
    border-color: $color-darkblue;
    a,span {
      color: $color-darkblue;
    }
    &:hover {
      background-color: $color-darkblue;
      a,span {
        color: $color-red;
      }
    }
  }
}

.primary-button {
  border-radius: 24px;
  display: flex;
  margin: 0 auto;
  transition: border $color-transition-timing $ease-out-quart, background-color $color-transition-timing linear, color $color-transition-timing $ease-in-out-quart;
  border: 2px solid;
  border-color: $color-darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  @include screen-tablet-portrait-and-bigger {
    width: 320px;
    height: 48px;
  }

  &.pb-em{
	margin-top:80px;
  }

  a,span {
    width: 100%;
    height: 100%;
    padding: $gutter-vertical-small*0.75 $gutter-vertical-micro;
    white-space: pre;
    
    @include screen-tablet-portrait-and-bigger {
      padding: $gutter-vertical-small*0.6 0;
      width: 320px;
    }
    text-decoration: none !important;
    color: $color-darkblue;
    @include text-style-desktop-m-medium;
    
  }
  &:hover {
    background-color: $color-darkblue;
    a,span {
      color:$color-yellow;
    }
  }
  &.sold-out {
    opacity: .3;
    pointer-events: none;
  }
}

.research-bar {
  border-radius: 6px;
  display: flex;
  transition: border $color-transition-timing linear, background-color $color-transition-timing linear, color $color-transition-timing linear;
  border: 2px solid;
  border-color: $color-darkblue;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  svg {
    margin: 0 1.5rem;
  }
  input[type='text']{
    border-radius: 0;
    padding: 1.1rem 1.25rem;
    background-color: transparent;
    font-size: initial;
    max-width: unset;
    min-width: unset;
    position: unset;
    width: 100%;
    @include screen-tablet-portrait-and-bigger {
      transition: padding $interaction-slow $ease-out-quart, width $interaction-slow $ease-out-quart;
      width: 265px;
    }
    
    &::placeholder {
      color: $color-darkblue;
    }
    &:focus {
      width: 100%;
      @include screen-tablet-portrait-and-bigger {
        width: 600px;
      }
      opacity: 1;
      &::placeholder {
        opacity: .3;
      }
    }
  }
}

