.content {
    .slider-active {
        margin: 0 0 0 0;
        transition: margin $interaction-timing $ease-out-quart;
        @include screen-tablet-landscape-and-bigger {
            margin: -85px 0 0 0;
        }
    }
}

.slider{
    button{
        display:none;
    }
    .slide{
        width:100%;
        img{
            width:100%;
        }
    }
}