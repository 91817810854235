// General form styles.
// form {
// }

fieldset {
  border: 0;
}

input {
  &[type='search'],
  &[type='text'] {
    border-radius: 500px;
    padding: $global-spacing * 1.5 $global-spacing-sm;
    outline: 0;
    border: 0;
    background-color: $color-off-white;
    color: $color-darkblue;
    font-size: 50%;
    max-width: 500px;
    min-width: 290px;
    width: 100%;
    position: relative;
  }
}

label[for='search'] {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: $global-spacing - 2px;
    right: $global-spacing-sm;
    background: url('/assets/images/search.svg') center no-repeat;
    width: 20px;
    height: 20px;
  }
}
