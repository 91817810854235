// Main styles.
main {
    overflow: hidden;
    &.default {
        overflow: hidden;
        height: 100vh;
        .content {
            padding-top: 0;
            height: 100vh;
            display: flex;
            .not-found {
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                z-index: 1;
                h1 {
                    color: $color-grey;
                    @include text-style-desktop-h-1;
                    margin-bottom: $gutter-vertical-small;
                    @include screen-tablet-landscape-and-bigger {
                        margin-bottom: $gutter-vertical-regular;
                    }
                }
                p {
                    color: $color-grey;
                    @include text-style-desktop-l-medium;
                }
            }
        }
        & + .whole-footer {
            .footer-row {
                border-color: $color-grey;
                a {
                    color: $color-grey;
                    &:hover {
                        opacity: .5;
                    }
                }
            }
        }
    }
}
