.content {
  .designer-exhibition {
    & > span.lead-caption {
      margin-bottom: $gutter-vertical-bigger*.25;
      @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-bigger*.5;
      }
    }
    p.lead {
      display: flex;
      justify-content: center;
      margin: 0 $gutter-vertical-small;
      span {
        margin-left: $gutter-vertical-micro;
        margin-right: $gutter-vertical-micro*-1;
        @include screen-tablet-landscape-and-bigger {
          margin-left: $gutter-vertical-small;
          margin-right: $gutter-vertical-small*-1;
        }
        width: 0px;
        display: flex;
        white-space: pre;
        overflow: visible;
        align-items: center;
        @include text-style-desktop-m-medium;
      }
    }
    .designer-exhibition-category {
      margin-top: $gutter-vertical-bigger*.25;
      @include screen-tablet-landscape-and-bigger {
        margin-top: $gutter-vertical-bigger*.5;
      }
      p {
        a {
          text-decoration: none;
          display: flex;
          white-space: pre;
        }
      }
      .designer-exhibition-category-time {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: $gutter-vertical-micro 0 0 0;
        span {
          padding: $gutter-vertical-micro $gutter-vertical-micro 0 $gutter-vertical-micro;
          color: $color-grey;
          @include text-style-desktop-m-medium;
        }
      }
    }
  }
  .designer-megascreens {
    span {
      margin-bottom: $gutter-vertical-regular;
    }
    a {
      margin-bottom: $gutter-vertical-micro;
      display: block;
      width: fit-content;
      margin: auto;
      h4, h5 {
		    transition: color $color-transition-timing linear;

      }
      &:hover {
        h4, h5 {
          color: $color-grey;
        }
      }
      h4 {
        @include text-style-desktop-xl-medium;
      }
      h5 {
        text-align: center;
        @include text-style-desktop-xl-medium;
        & + .caption {
          color: $color-grey;
        }
      }
    }
  }
  .designer-work-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-yellow;
    align-self: center;
    span {
      margin-bottom: $gutter-vertical-bigger*.25;
      @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-bigger*.5;
      }
      color: $color-grey;
    }

    .designer-work {
      & + .designer-work {

        margin-top: $gutter-vertical-big;
        @include screen-tablet-landscape-and-bigger {
          margin-top: $gutter-vertical-bigger;
        }
      }
    }

    .designer-work-images {
      .designer-work-images-wrapper {
        display: flex;
        flex-direction: row;
        gap: $gutter-vertical-micro*.8;
        @include screen-tablet-landscape-and-bigger {
          gap: $gutter-vertical-small;
        }
        figure {
          background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
          width: 100%;
          img {
            aspect-ratio: 9/16;
          }
        }
      }
      .designer-work-caption {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: $gutter-vertical-micro*1.6;
        @include screen-tablet-landscape-and-bigger {
          margin-top: $gutter-vertical-regular;
        }
        h5 {
          text-align: center;
          @include text-style-desktop-l-medium;
        }
      }
    }

    .designer-work-image {
      figure {
				img {
					aspect-ratio: auto;
					max-height: 50vh;
					max-width: 100%;
					width: auto;
					margin: 0 auto 20px auto;
					display: block;
          background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
          margin-bottom: $gutter-vertical-regular;
				}
        figcaption {
          h5 {
            text-align: center;
            @include text-style-desktop-l-medium;
          }
        }
			}
      & + .designer-work-image {
        margin-top: $gutter-vertical-bigger;
      }
    }
  }
}