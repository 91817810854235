@font-face {
	font-family: 'GraphikWide';
	font-display: block;
	src: url('../fonts/GraphikWide-Medium-Web.woff') format('woff');
	font-weight: $font-weight-medium;
}

@font-face {
	font-family: 'GraphikWide';
	font-display: block;
	src: url('../fonts/GraphikWide-Super-Web.woff') format('woff');
	font-weight: $font-weight-super;
}
  