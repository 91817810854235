// sass-lint:disable no-vendor-prefixes, no-important

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  min-height: 100%;
  color: $color-darkblue;
  max-width: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
  display: flex;
  min-height: 100vh;
  justify-content: space-between;
  flex-direction: column;
}

html {
  font-family: $font-stack-primary;
  font-size: $font-size-root;
  font-weight: $font-weight-medium;
  color: $color-text;
  background-color: $color-yellow;
  min-height: 100%;
  scroll-behavior: smooth;

  &.no--scroll {
    overflow-y: hidden !important;
  }
}

body {
  @include text-style-desktop-s-medium;
  hyphens: auto;
  word-break: normal;
}

strong {
  font-weight: $font-weight-super;
}
