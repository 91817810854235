.o-container {
	&:last-child {
		margin-bottom: $gutter-vertical-bigger;
		@include screen-tablet-landscape-and-bigger {
			margin-bottom: $gutter-vertical-huge;
		}
	}
}

.content {
	
	// ————————————————————————————————————————————
	// Main Sizes and distances
	// ————————————————————————————————————————————
	
	section {
		margin-bottom: $gutter-vertical-big;
		@include screen-tablet-landscape-and-bigger {
			margin-bottom: $gutter-vertical-huge;
		}
		&.no-bottom-margin {
			margin-bottom: 0;
		}
		&.no-top-margin {
			margin-top: 0;
		}
		&.biggest-bottom-margin {
			margin-bottom: $gutter-vertical-bigger;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-biggest;
			}
		}
		&.biggest-bottom-margin-mobile {
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-biggest;
			}
		}
		&.bigger-bottom-margin {
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-bigger;
			}
		}
		&.big-bottom-margin {
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-big;
			}
		}
		&.regular-bottom-margin {
			margin-bottom: $gutter-vertical-regular;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-regular;
			}
		}
		&.regular-top-margin {
			margin-top: $gutter-vertical-regular;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-big;
			}
		}
		&.small-top-margin {
			margin-top: $gutter-vertical-small;
			@include screen-tablet-landscape-and-bigger {
				margin-top: 0;
			}
		}
		&.insane-top-margin {
			margin-top: $gutter-vertical-insane;
		}
		&.bigger-top-margin {
			margin-top: $gutter-vertical-bigger;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-bigger;
			}
		}
		&.biggest-top-margin {
			margin-top: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-insane;
			}
		}
		&.huge-top-margin {
			margin-top: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-huge;
			}
		}
		&.small-bottom-margin-mobile {
			margin-bottom: $gutter-vertical-small;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-huge;
			}
		}
		&.small-bottom-margin {
			margin-bottom: $gutter-vertical-small;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-small;
			}
		}
	}
	&.content--nomargin {
		margin-bottom: 0;
	}
	
	// ————————————————————————————————————————————
	// Basic Typographic elements
	
	p {
		hyphens: none;
		transition: color $color-transition-timing linear;
		&.lead {
			text-align: center;
			@include text-style-desktop-xl-medium;
			& + button {
				margin-top: $gutter-vertical-regular;
			}
			&.link-list {
				display: flex;
				a {
					text-decoration: none;
					display: contents;
					white-space: pre;
				}
			}
		}
		&.small-lead {
			text-align: center;
			padding: 0 $gutter-vertical-small;
			@include text-style-desktop-l-medium;
		}
		a:hover {
			color: $color-grey;
			text-decoration: none;
		}

		&.about-paragraph{
			strong{
				color: $color-darkblue;
				font-weight: 500;
			}
			margin-bottom:160px;
		}

		&.paragraph-smaller{
			font-size: 1.9rem;
		}

	}
	
	a {
		text-decoration: underline;
		&.huge-link {
			text-decoration: none;
			display: block;
			border-top: 1px solid $color-grey;
			border-bottom: 1px solid $color-grey;
			padding: 15px 0;
			text-align: center;
			color: $color-grey;
			margin-bottom: 30px;
		}
	}
	
	h1, h2 {
		color: $color-yellow;
		& + h4 {
			margin-top: $gutter-vertical-regular;
		}
	}
	
	h3 {
		@include text-style-desktop-h-3-medium;
		text-align: center;
	}
	
	h4 {
		@include text-style-desktop-xl-medium;
		text-align: center;
		& + button {
			margin-top: $gutter-vertical-regular;
		}
	}
	
	// ————————————————————————————————————————————
	// Layout elements
	// —— Header ——————————————————————————————————
	
	.page-header {
		margin: $gutter-vertical-bigger 0 $gutter-vertical-big 0;
		@include screen-tablet-landscape-and-bigger {
			margin: $gutter-vertical-biggest 0 $gutter-vertical-huge 0;
		}
		h2.page-header-title {
			text-align: center;
			display: flex;
			justify-content: center;
			@include text-style-desktop-h-2-super;
			& + p {
				// @include text-style-desktop-m-medium;
				margin: $gutter-vertical-micro*1.6 auto 0 auto;
				width: 100%;
				@include screen-tablet-landscape-and-bigger {
					margin: $gutter-vertical-regular auto 0 auto;
					width: 75%;
				}
			}
		}
		div.page-header-title {
			& + p {
				margin: $gutter-vertical-micro*6 0 0 0;
				@include screen-tablet-landscape-and-bigger {
					margin: $gutter-vertical-big 0 0 0;
				}
			}
		}
		h3.page-header-subtitle {
			display: flex;
			justify-content: center;
			@include text-style-desktop-h-2-medium;
			& + .caption {
				margin-top: $gutter-vertical-small;
				@include screen-tablet-landscape-and-bigger {
					margin-top: $gutter-vertical-regular
				}
			}
		}
		ul.page-header-links-wrapper {
			display: flex;
			justify-content: space-between;
			// justify-content: space-around;
			list-style: none;
			margin: $gutter-vertical-micro*6 0 0 0;
			@include screen-tablet-landscape-and-bigger {
				margin: $gutter-vertical-bigger 0 0 0;
			}
			li {
				@include fluid-type(35px, 14px);
				font-weight: $font-weight-medium;
				line-height:1.1;
				letter-spacing:0em;
				@include screen-tablet-landscape-and-bigger {
					line-height:1.05;
					letter-spacing:-0.01em;
				}
				&.current-page {
					border-bottom: 2px solid $color-darkblue;
					padding: 0 0 $gutter-vertical-micro*.5 0;
					margin: 0 0 $gutter-vertical-micro*-.5 0;
					@include screen-tablet-landscape-and-bigger {
						padding: 0 0 $gutter-vertical-micro 0;
						margin: 0 0 $gutter-vertical-micro*-1 0;
					}
					a {
						opacity: 1;
					}
				}
				a {
					opacity: .3;
					text-decoration: none;
					transition: opacity $color-transition-timing linear;
					white-space: pre;
					&:hover {
						opacity: 1;
					}
				}
				.menu--is-active & {
					@include fluid-type(26px, 12px);
				}
			}
			& + p.lead {
				margin-top: $gutter-vertical-micro*6;
				@include screen-tablet-landscape-and-bigger {
					margin-top: $gutter-vertical-bigger;
				}
			}
		}
		&.small-bottom-margin {
			margin: $gutter-vertical-bigger 0 $gutter-vertical-bigger*.5 0;
			@include screen-tablet-landscape-and-bigger {
				margin: $gutter-vertical-biggest 0 $gutter-vertical-big 0;
			}
		}
		.lead-caption + h2.page-header-title {
			margin-top: $gutter-vertical-micro*1.6;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-regular;
			}
		}
		.buttons-wrapper {
			display: flex;
			justify-content: center;
			margin-top: $gutter-vertical-regular;
			column-gap: $gutter-vertical-small;
			@include screen-tablet-landscape-and-bigger {
				column-gap: 0;
			}
			button {
				margin: 0 0;
				@include screen-tablet-landscape-and-bigger {
					margin: 0 $gutter-vertical-small;
				}
			}
		}
		div.caption {
			display: flex;
			justify-content: space-around;
			margin: $gutter-vertical-regular auto 0 auto;
			@include screen-tablet-landscape-and-bigger {
				margin: $gutter-vertical-big auto 0 auto;
			}
			@include text-style-desktop-m-medium;
			span {
				color: $color-grey;
				white-space: pre;
				&:first-of-type {
					color: $color-darkblue;
				}
			}
		}
		&.event {
			margin: $gutter-vertical-huge 0;
			.caption {
				margin-top: 0;
			}
			.page-header-title {
				margin: $gutter-vertical-regular 0;
			}
			.event-image {
				height: auto;
				width: 100%;
				aspect-ratio: 1/1;
				margin: 0 auto $gutter-vertical-bigger auto;
				@include screen-tablet-portrait-and-bigger {
					height: 50vh;
					width: unset;
				}
				img {
					background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
					height: 100%;
				}
			}
		}
	}
	
	// —— Titles ——————————————————————————————————
	
	.lead-title-huge {
		color: $color-darkblue;
		h2 {
			@include text-style-desktop-h-2-medium;
			text-align: center;
			display: flex;
			justify-content: center;
		}
		.caption {
			margin-top: $gutter-vertical-small;
			@include screen-laptop-and-bigger {
				margin-top: $gutter-vertical-regular;
			}
			& + button {
				margin-top: $gutter-vertical-regular;
			}
			.menu--is-active & {
				max-width: 50%;
				right: -25%;
			}
		}
		&.video-wrapper {
			display: flex;
			flex-direction: column;
			align-self: center;
			row-gap: $gutter-vertical-small;
			margin-bottom: $gutter-vertical-micro*6;
			@include screen-laptop-and-bigger {
				row-gap: $gutter-vertical-micro*3;
				margin-bottom: $gutter-vertical-micro*20;
			}
			h2 {
				height: 0;
				@include screen-laptop-and-bigger {
					white-space: pre; 
				}
			}
			span {
				height: 0;
				margin-bottom: 0;
				color: $color-yellow;
			}
		}
	}
	ul.huge-list {
		text-align: center;
		list-style: none;
		margin-bottom: $gutter-vertical-regular;
		li {
			white-space: nowrap;
			@include text-style-desktop-h-3-medium;
			a {
				text-decoration: none;
				transition: color $color-transition-timing linear;
				&:hover {
					color: $color-off-white;
				}
			}
		}
		& + button {
			margin-top: $gutter-vertical-regular;
		}
		& + .caption {
			margin-top: $gutter-vertical-regular;
		}
		&.hidden {
			display: none;
		}

		&.darkblue li a{
			color: $color-darkblue;
			&:hover{
				color: $color-off-white;
			}
		}

	}
	ul.scrolling-list {
		display: flex;
		flex-direction: row;
		overflow: scroll;
		margin-top: $gutter-vertical-regular;
		margin-bottom: $gutter-vertical-regular;
		padding: 0 $gutter-vertical-small;
		@include screen-tablet-landscape-and-bigger {
			padding: 0;
		}
		div.scrolling-list-column {
			display: flex;
			flex-direction: column;
			padding-right: $gutter-vertical-small;
			@include text-style-desktop-l-medium;
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow-y: hidden;
				overflow-x: hidden;
				margin-bottom: $gutter-vertical-micro/4;
				a {
					text-decoration: none;
					&:hover {
						color: $color-grey;
					}
				}
			}
		}
		&.hidden {
			display: none;
			&+.primary-button {
				.list-switch-close {
					display: none;
				}
				.list-switch-open {
					display: block;
				}
			}
		}
		&+.primary-button {
			width: calc(100% - #{$gutter-vertical-regular});
			@include screen-tablet-landscape-and-bigger {
				width: 320px;
			}
			.list-switch-close {
				display: block;
			}
			.list-switch-open {
				display: none;
			}
		}
	}
	h3 {
		margin-bottom: $gutter-vertical-micro*3;
		&.big-margin-bottom {
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-bigger;
			}
		}
		hyphens: none;
	}
	
	// —— Paragraphs ——————————————————————————————————
	
	.lead-paragraph {
		text-align: center;
		margin-bottom: 0;
		margin-top: $gutter-vertical-bigger;
		@include screen-tablet-landscape-and-bigger {
			margin-top: $gutter-vertical-huge;
		}
		@include text-style-desktop-l-medium;
		& + .lead-paragraph {
			margin-top: $gutter-vertical-small;
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-huge;
				margin-top: $gutter-vertical-micro*6;
			}
		}

		&.lead-paragraph-nm{
			margin-top:0;
		}

	}
	
	// —— Captions ——————————————————————————————————
	
	span {
		&.lead-caption {
			display: block;
			@include text-style-desktop-m-medium;
			text-align: center;
			margin-bottom: $gutter-vertical-small;
			& + .huge-list {
				margin-top: $gutter-vertical-small;
				@include screen-tablet-landscape-and-bigger {
					margin-top: $gutter-vertical-regular;
				}
				&.small-margin {
					margin-top: $gutter-vertical-small;
					& + .caption {
						margin-top: $gutter-vertical-small;
					}
					@include screen-tablet-landscape-and-bigger {
						margin-top: $gutter-vertical-regular;
					}
				}
			}
		}
		& + .vertical-gallery {
			margin-top: $gutter-vertical-micro*6;
		}
	}
	.caption {
		display: block;
		hyphens: none;
		@include text-style-desktop-m-medium;
		text-align: center;
		margin-top: $gutter-vertical-micro*1.5;
		@include screen-tablet-landscape-and-bigger {
			margin-top: $gutter-vertical-small;
		}
		&.extra-size {
			@include text-style-desktop-sl-medium;
			margin-bottom: 0;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-micro*6;
			}
		}
	}
	.link-caption {
		display: block;
		@include text-style-desktop-m-medium;
		text-align: center;
		margin-top: $gutter-vertical-small;
	}
	
	// —— Images ——————————————————————————————————
	
	figure {
		& + button {
			margin-top: $gutter-vertical-regular/1.25;
			@include screen-tablet-landscape-and-bigger {
				margin-top: $gutter-vertical-big;
			}
		}
		video {
			display: block;
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}
	img {
		width: 100%;
	}
	
	.lead-image {
		display: flex;
		overflow: hidden;
		position: relative;
		margin: 0 0 $gutter-vertical-small 0;
		height: 90vh;
		@include screen-tablet-landscape-and-bigger {
			height: 95vh;
		}
		.BGImage {
			position: absolute;
			width: 100%;
			height: 100%;
			figure {
				height: 100%;
				video {
					object-fit: cover;
					height: 100%;
				}
			}
			img {
				object-fit: cover;
				height: 100%;
			}
		}
		&.video-natural-height {
			height: auto;
			max-height: 95vh;
			margin: 0;
			.BGImage {
				position: relative;
				width: 100%;
				height: 100%;
				figure {
					height: 100%;
					video {
						height: 100%;
					}
				}
			}
		}
	}
	.lead-BGImage-landscape {
		display: flex;
		overflow: hidden;
		position: relative;
	}
	.lead-BGImage {
		display: flex;
		overflow: hidden;
		position: relative;
		height: 95vh;
		.BGImage {
			position: absolute;
			width: 100%;
			height: 100%;
			figure {
				height: 100%;
				video {
					object-fit: cover;
					height: 100%;
				}
			}
			img {
				object-fit: cover;
				height: 100%;
			}
		}
		.lead-caption-wrapper {
			color: $color-yellow;
			align-self: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			padding: 0 0;
			min-height: 90vh;
			@include screen-tablet-landscape-and-bigger {
				padding: 0 $gutter-vertical-regular;
				min-height: 95vh;
			}
			span {
				color: $color-yellow;
				.theme-yellow & {
					color: $color-darkblue;
				}
			}
			.theme-yellow & {
				color: $color-darkblue;
			}
		}
	}
	
	.gallery-wrapper {
		display: flex;
		overflow: hidden;
		position: relative;
		height: 50vh;
		@include screen-tablet-landscape-and-bigger {
			height: 95vh;
		}
		.gallery {
			position: absolute;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			.gallery-item {
				opacity: 0;
				position: absolute;
				width: 100%; 
				height: 100%;
				figure {
					width: 100%; 
					height: 100%;
					img {
						object-fit: cover;
						height: 100% ;
					}
				}
			}
			.gallery-item_visible {
				opacity: 1;
			}
			.themes-gallery-item {
				opacity: 0;
				position: absolute;
				width: 100%; 
				height: 100%;
				video {
					width: 100%; 
					height: 100%;
					object-fit: cover;
				}
				figure {
					width: 100%; 
					height: 100%;
				}
			}
			.themes-gallery-item_visible {
				opacity: 1;
			}
		}
		.lead-caption-wrapper {
			justify-content: flex-end;
			min-height: calc(100% - #{$gutter-vertical-big});
			display: flex;
			flex-direction: column;
			margin-bottom: $gutter-vertical-regular;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-big;
			}
		}
	}
	.lead-caption-wrapper {
		.vertical-gallery {
			.vertical-gallery-image {
				figure {
					img {
						background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
						margin-bottom: $gutter-vertical-small;
						@include screen-tablet-landscape-and-bigger {
							margin-bottom: $gutter-vertical-regular;
						}
					}
					figcaption {
						h4, h5 {
							transition: color $color-transition-timing linear;
						}
						h4 {
							@include text-style-desktop-xl-super;
						}
						h5 {
							text-align: center;
							@include text-style-desktop-xl-medium;
						}
					}
				}
			}
			a.vertical-gallery-image{
				&:hover {
					figure {
						figcaption {
							h4,h5 {
								color: $color-grey;
							}
						}
					}
				}
			}
			& + .vertical-gallery {
				margin-top: $gutter-vertical-big;
				@include screen-tablet-landscape-and-bigger {
					margin-top: $gutter-vertical-bigger;
				}
			}
		}
	}
	.lead-BGImage-huge {
		display: flex;
		overflow: hidden;
		position: relative;
		height: 90vh;
		@include screen-tablet-landscape-and-bigger {
			height: 95vh;
			margin-bottom: $gutter-vertical-big;
		}
		.BGImage {
			position: absolute;
			width: 100%;
			height: 100%;
			img {
				object-fit: cover;
				height: 100%;
			}
			video {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
			&.demoverse {
				display: flex;
				align-items: center;
				cursor: grab;
				// background-color: $color-darkblue;
				justify-content: center;
				// background: linear-gradient(0deg, rgba(7,3,35,1) 19%, rgba(75,74,89,1) 54%, rgba(132,132,133,1) 92%);
				pointer-events: none;
				@include screen-tablet-landscape-and-bigger {
					pointer-events: auto;
				}
				canvas {
					display: flex;
					background: transparent;
					width: 100%;
					height: 75%;
					@include screen-tablet-landscape-and-bigger {
						height: 90%;
					}
				}
				& + .lead-caption-wrapper {
					pointer-events: none;
					display: flex;
					flex-direction: column;
					gap: 75%;
					align-items: center;
					height: 100%;
					@include screen-tablet-landscape-and-bigger {
						gap: 33.3333333%;
						flex-direction: row;
					}
					p {
						width: calc(100% - #{$gutter-vertical-regular});
						text-align: center;
						@include text-style-desktop-l-medium;
						button {
							pointer-events: all !important;
						}
					}
					.menu--is-active & {
						flex-direction: column;
						gap: 85%;
					}
				}
			}
		}
		.lead-caption-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: $color-yellow;
			align-self: center;
			min-height: 90vh;
			position: relative;
			padding: 0 0;
			@include screen-tablet-landscape-and-bigger {
				padding: 0 $gutter-vertical-regular;
			}
			span {
				color: $color-grey;
			}
			h2 {
				color: $color-yellow;
				text-align: center;
				@include text-style-desktop-h-2-super;
				display: flex;
				justify-content: center;
				&+button {
					margin-top: $gutter-vertical-regular ;
				}
			}
			h3 {
				color: $color-yellow;
				text-align: center;
				@include text-style-desktop-xl-medium;
			}
		}
		.page-header {
			margin-top: 160px;
			margin-bottom: 160px;
			span.lead-caption {
				color: $color-yellow;
				margin-bottom: 0px;
			}
		}
	}
	
	// —— Illustrations ——————————————————————————————————
	
	.illustration-container {
		svg {
			overflow: visible;
			width: 100%;
			height: auto;
			@include screen-tablet-landscape-and-bigger {
				height: unset;
			}
		}
		.illustration-container-image {
			display: flex;
			margin: 0 0 $gutter-vertical-micro*6 0;
			@include screen-tablet-landscape-and-bigger {
				margin: 0 0 0 0;
			}
		}
		.illustration-container-caption {
			text-align: center;
			align-self: center;
			p {
				@include text-style-desktop-l-medium;
			}
		}
		&.reverse-on-mobile {
			flex-direction: column-reverse;
			@include screen-tablet-landscape-and-bigger {
				flex-direction: row;
			}
		}
		.menu--is-active & {
			flex-direction: column;
			.illustration-container-image {
				flex-basis: 80%;
				max-width: 80%;
				right: -10%;
				margin-bottom: $gutter-vertical-micro*6;
			}
			.illustration-container-caption {
				flex-basis: 80%;
				max-width: 80%;
				right: 0;
			}
			&.reverse-on-mobile {
				flex-direction: column-reverse;
			}
		}
	}
	
	// —— Boring content ——————————————————————————————————
	
	.privacy, .disclamer, .terms {
		h1 {
			text-transform: unset;
			text-align: center;
			@include text-style-desktop-h-3-super;
			margin-bottom: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-bigger;
			}
		}
		h2 {
			color: $color-yellow ;
			margin-bottom: $gutter-vertical-micro*.5;
			text-transform: unset;
			@include text-style-desktop-m-super;
		}
		p {
			color: $color-grey;
			margin-bottom: $gutter-vertical-small ;
			@include text-style-desktop-m-medium;
			strong {
				display: block;
				text-transform: unset;
				color: $color-yellow;
				margin-bottom: $gutter-vertical-micro*.5;
				@include text-style-desktop-m-super;
			}
		}
		ul {
			color: $color-grey;
			margin-bottom: $gutter-vertical-small;
			margin-left: $gutter-vertical-small;
			@include text-style-desktop-m-medium;
		}
	}
	
	&.content--boring {
		padding-top: $gutter-vertical-huge;
		@include screen-tablet-landscape-and-bigger {
			padding-top: $gutter-vertical-biggest;
		}
	}
	
	// —— Columns ——————————————————————————————————
	
	.o-grid {
		&.two-columns {
			display: flex;
			flex-direction: column;
			margin-top: $gutter-vertical-micro*10;
			row-gap: $gutter-vertical-big;
			@include screen-tablet-landscape-and-bigger {
				row-gap: 0;
				margin-top: $gutter-vertical-huge;
				flex-direction: row;
			}
			div.list-left_align {
				width: 100%;
				display: flex;
				flex-direction: column;
				row-gap: $gutter-vertical-micro*6;
				padding: 0 0 0 0;
				@include screen-tablet-landscape-and-bigger {
					padding: 0 $gutter-vertical-regular 0 0;
					row-gap: $gutter-vertical-bigger;
					flex-direction: column;
					width: 50%;
				}
				&.smaller {
					p.lead {
						@include text-style-desktop-l-medium;
						line-height:1.17;
					}
				}
				p.lead {
					display: flex;
					flex-direction: column;
					text-align: left;
					row-gap: 4px;
					@include screen-tablet-landscape-and-bigger {
						row-gap: 2px;
					}
					em {
						@include text-style-desktop-m-medium;
						font-style: inherit;
						margin-bottom: $gutter-vertical-micro*-.4;
						@include screen-tablet-landscape-and-bigger {
							margin-bottom: $gutter-vertical-micro*.5;
						}
						& + br {
							display: none;
						}
					}
					span {
						display: flex;
						width: 100%;
						text-align: left;
						a {
							text-align: left;
							text-decoration: none;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow-y: hidden;
							overflow-x: hidden;
							line-height: 1.2;
							margin-bottom: -.2em;
							&:hover{
								color: $color-darkblue;
							}
						}
						&.lead-caption {
							color: $color-grey;
							margin-bottom: $gutter-vertical-micro*1.5;
							@include screen-tablet-landscape-and-bigger {
								margin-bottom: $gutter-vertical-micro*3;
							}
						}
						&.caption {
							margin-top: $gutter-vertical-small;
							@include screen-tablet-landscape-and-bigger {
								margin-top: $gutter-vertical-regular;
							}
						}
					}
				}
			}
			&.designers-list{
				div.list-left_align p.lead span.is-hidden{
					display:none;
				}
				row-gap: 4px;
			}
		}
	}
	
	// —— Sections ——————————————————————————————————
	
	.blue-section {
		padding: $gutter-vertical-big $gutter-vertical-small;
		@include screen-tablet-landscape-and-bigger {
			padding: $gutter-vertical-huge 0;
		}
		& + .blue-section {
			padding-top: 0;
		}
	}
	.year_button-section {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: $gutter-vertical-big $gutter-vertical-small;
		height: 70vh;
		@include screen-tablet-landscape-and-bigger {
			height: 100vh;
			// padding: $gutter-vertical-huge $gutter-vertical-small;
		}
		& + .year_button-section {
			padding-top: 0;
		}

		.extra-info-section{
			margin: 120px 0;
			width:100%;
			display: flex;
			/* child elements spaced out horizontally */
			justify-content: space-between;
			color: $color-middlegrey;
			margin-bottom: $gutter-vertical-small ;
			@include text-style-desktop-m-medium;

		}

		&.year_button-section-np{
			padding:0;
			.extra-info-section{
				color: $color-off-white;
			}
		}

	}
	
	// —— Other elements ——————————————————————————————————
	
	.scrollAnchor {
		display: block;
		background: transparent;
		height: $gutter-vertical-micro*10;
		width: $gutter-vertical-regular;
		position: absolute;
	}
	
	.category-part-slider::-webkit-scrollbar,
	.takeover-part-slider::-webkit-scrollbar {
		display: none;
	}
	
	&.empty-placeholder {
		display: none;
		margin: $gutter-vertical-bigger 0 $gutter-vertical-bigger 0;
		text-align: center;
		@include text-style-desktop-xl-medium;
		&.active {
			display: block;
		}
	}
	
	.theme-2025 &, 
	.general & {
		.automatic-gallery {
			width: 400%;
			@include screen-tablet-landscape-and-bigger {
				width: 200%;
			}
			margin-top: $gutter-vertical-big;
			.automatic-gallery-row {
				display: flex;
				// column-gap: $gutter-vertical-micro;
				.automatic-gallery-element {
					position: relative;
					display: flex;
					justify-content: center;
					overflow: hidden;
					margin-right: $gutter-vertical-micro;
					width: calc(50vw);
					@include screen-tablet-landscape-and-bigger {
						width: calc(20vw - 20px);
					}
					figure {
						display: flex;
						transition: transform $interaction-slow $ease-out-quart, filter $interaction-slow $ease-out-quart;
					}
					figcaption {
						position: absolute;
						top: 50%;
						width: 70%;
						height: 0;
						display: flex;
						align-items: center;
						text-align: center;
						justify-content: center;
						opacity: 0;
						user-select: none;
						@include text-style-desktop-m-medium;
						color:$color-white;
					}
					&:hover {
						figure {
							filter: blur(10px);
							transform: scale(1.1);
						}
						figcaption {
							opacity: 1;
						}
					}
				}
				& + .automatic-gallery-row {
					margin-top: $gutter-vertical-micro;
				}
			}
		}
		.data-overview {
			display: none;
			@include screen-tablet-landscape-and-bigger {
				display: flex;
				.menu--is-active & {
					display: none;
				}
			}
			
			flex-direction: row;
			margin-top: $gutter-vertical-big;
			padding: 0 $gutter-vertical-big;
			.list-left_align {
				display: flex;
				flex-direction: column;
				padding: 0 $gutter-vertical-bigger;
				row-gap: $gutter-vertical-big;
				width: 50%;
				color: $color-yellow;
				&:first-of-type {
					color: $color-red;
					border-right: 2px solid $color-grey;
				}
				.lead-paragraph {
					color: $color-grey;
					margin-top: 0;
				}
			}
			.data-overview-block {
				figure {
					display: flex;
					justify-content: center;
					img {
						height: $gutter-vertical-big;
						@include screen-laptop-and-bigger {
							height: $gutter-vertical-bigger;
						}
						width: auto;
					}
				}
			}
		}
		.data-overview-mobile {
			display: flex;
			flex-direction: row;
			overflow: scroll;
			column-gap: $gutter-vertical-small;
			padding: 0 $gutter-vertical-regular;
			@include screen-tablet-landscape-and-bigger {
				display: none;
				.menu--is-active & {
					display: flex;
				}
			}
			.data-card {
				display: flex;
				flex-direction: column;
				padding: $gutter-vertical-small;
				border: 2px solid $color-grey;
				border-radius: 22px;
				h5 {
					@include text-style-desktop-l-medium;
					color: $color-grey;
					text-align: center;
					margin-bottom: $gutter-vertical-small;
				}
				.data-card-wrapper{
					@include text-style-desktop-m-medium;
					text-align: center;
					border-top: 2px $color-grey solid;
					padding: $gutter-vertical-regular 0;
					.data-card-slot-top {
						color: $color-red;
						display: flex;
						flex-direction: column;
						row-gap: $gutter-vertical-small;
						margin-bottom: $gutter-vertical-micro;
						div {
							height: 17vw;
							.menu--is-active & {
								height: 8vw;
							}
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
					.data-card-slot-bottom {
						color: $color-yellow;
						display: flex;
						flex-direction: column;
						row-gap: $gutter-vertical-small;
						div {
							height: 17vw;
							.menu--is-active & {
								height: 8vw;
							}
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
			}
		}
	}
	.logos-overview {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		justify-content: center;
		margin-top: $gutter-vertical-big;
		@include screen-tablet-landscape-and-bigger {
			margin-top: $gutter-vertical-bigger;
		}
		&.multiple-rows {
			margin-top: $gutter-vertical-micro*2;
		}
		.partner-logo {
			display: flex;
			align-items: center;
			flex: 0 1 24%;
			height: $gutter-vertical-micro*5.5;
			aspect-ratio: 1.5/1;
			@include screen-mobile-plus-and-bigger {
				flex: 0 1 18%;
				height: $gutter-vertical-micro*8;
			}
			@include screen-tablet-landscape-and-bigger {
				flex: 0 1 16%;
				height: $gutter-vertical-micro*10;
			}
			@include screen-laptop-and-bigger {
				flex: 0 1 16%;
				height: $gutter-vertical-micro*9;
			}
			svg {
				path, polygon, rect {
					fill: $color-grey;
					stroke: $color-grey;
				}
				.theme-red & {
					path, polygon, rect {
						fill: $color-darkblue;
						stroke: $color-darkblue;
					}
				}
				.theme-darkyellow & {
					path, polygon, rect {
						fill: $color-yellow;
						stroke: $color-yellow;
					}
				}
				.theme-darkred & {
					path, polygon, rect {
						fill: $color-red;
						stroke: $color-red;
					}
				}
				.theme-yellow & {
					path, polygon, rect {
						fill: $color-darkblue;
						stroke: $color-darkblue;
					}
				}
			}

			&:hover{
				svg {
					path, polygon, rect {
						fill: $color-darkblue;
						stroke: $color-darkblue;
					}
				}
			}

		}
		
	}
	.logo-group {
		margin-bottom: $gutter-vertical-micro*6;
		@include screen-tablet-landscape-and-bigger {
			margin-bottom: 0;
		}
		
		&:first-of-type {
			margin-bottom: $gutter-vertical-micro*6;
			@include screen-tablet-landscape-and-bigger {
				margin-bottom: $gutter-vertical-micro*8;
			}
		}
	}
}

.photography-layouts {
	.demo-images {
		row-gap: $gutter-vertical-micro*.5;
		@include screen-tablet-landscape-and-bigger {
			row-gap: $gutter-vertical-micro;
		}
		.demo-images-double-column {
			display: flex;
			flex-direction: column;
			column-gap: $gutter-vertical-micro * .5;
			row-gap: $gutter-vertical-micro * .5;
			@include screen-tablet-landscape-and-bigger {
				flex-direction: row;
				row-gap: $gutter-vertical-micro;
				column-gap: $gutter-vertical-micro;
			}
			.o-col-12--lg {
				flex-shrink: 1;
			}
		}
	}
}

.past-page-special {
	padding-top: $gutter-vertical-regular;
	@include screen-tablet-landscape-and-bigger {
		padding-top: $gutter-vertical-huge;
	}
	h2 {
		@include text-style-desktop-xl-medium;
		text-align: center;
		margin-bottom: $gutter-vertical-regular;
		@include screen-tablet-landscape-and-bigger {
			margin-bottom: $gutter-vertical-bigger;
		}
	}
	p.large{
		margin-bottom:$gutter-vertical-big;
		@include screen-tablet-landscape-and-bigger {
			margin-bottom:$gutter-vertical-huge;
		}
		strong{
			@include text-style-desktop-xl-medium;
		}
	}
	.demo-images {
		row-gap: $gutter-vertical-micro*.5;
		@include screen-tablet-landscape-and-bigger {
			row-gap: $gutter-vertical-micro;
		}
	}
	.title-gif {
		figure {
			display: contents;
			@include screen-tablet-landscape-and-bigger {
				display: block;
			}
		}
	}
}

.blue-section {
	h2 {
		color: $color-yellow;
		text-align: center;
		@include text-style-desktop-h-2-medium;
	}
	figure {
		padding: 0 $gutter-vertical-small;
	}
}

.demoverse-container{
	margin-top: $gutter-vertical-big;
}

.critlist{
	h3, h4{
		text-align: left;
		font-size: 42px;
		margin-bottom: 10px;
	}
	.caption{
		text-align: left;
	}
}

.about-section{
	margin-bottom:80px;
	.about-container {
		display: flex;
		align-items: center; /* Vertically center the text */
		gap: 160px;
		.image,
		.text {
			flex: 1; /* Equal width for both elements */
		}
		.text {
			display: flex;
			align-items: center; /* Vertically center the text within its container */
			justify-content: center; /* Optionally center the text horizontally */
		}
	}

	.about-paragraph{
		strong{
			color: $color-darkblue;
			font-weight: 500;
		}
		margin-bottom:160px;
		text-align:left;
	}

	

}
