.carousel {
    display: grid;
    grid-auto-flow: column;
    padding: 0 $gutter-vertical-small;
    padding-top: $gutter-vertical-big;
    overflow: scroll;
    overflow-y: auto;
    padding-bottom: 0;
    @include screen-tablet-landscape-and-bigger {
        padding-bottom: $gutter-vertical-small;
    }
    
    .carousel-wrapper {
        display: flex;
        gap: $gutter-vertical-micro*1.5;
        @include screen-tablet-landscape-and-bigger {
            gap: $gutter-vertical-small;
        }
        .carousel-card {
            position: relative;
            display: flex;
            justify-content: center;
            border-radius: 22px;
            overflow: hidden;
            aspect-ratio: 4/6;
            position: relative;
            scroll-snap-align: center;
            width: min-content;
            background-color: $color-darkblue;
            text-decoration: none;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            height: 50vh;
    
            @include screen-tablet-landscape-and-bigger {
                height: 70vh;
            }
    
            .carousel-card-BG {
                height: 100%;
                width: 100%;
                position: absolute;
                figure {
                    height: 100%;
                    margin: 0;
                    video {
                        height: 100%;
                    }
                }
                video {
                    height: calc(100% + 1px);
                    object-fit: cover;
                    width: 100%;
                }
            }
            .carousel-card-caption {
                top: 0;
                width: 80%;
                align-self: center;
                text-align: center;
                justify-self: center;
                position: inherit;
                user-select: none;
                color: $color-yellow;
                display: flex;
                justify-content: center;
                @include text-style-desktop-xl-medium;
            }
            &:hover {
    
                .carousel-card-button {
                    background-color: $color-yellow;
                    transition: border $color-transition-timing linear, background-color $color-transition-timing linear, color $color-transition-timing linear;
                    span {
                        color: $color-darkblue;
                    }
                }
            }
            .carousel-card-button {
                position: absolute;
                bottom: 0;
                text-align: center;
                justify-content: center;
                width: calc(100% - #{$gutter-vertical-micro*6});
                margin: $gutter-vertical-micro*3;
                @include screen-tablet-landscape-and-bigger {
                    width: calc(100% - #{$gutter-vertical-big});
                    margin: $gutter-vertical-regular;
                }
            }
            transition: transform $interaction-timing $ease-in-out-quad;
        }
        .card-hovering {
            transform: scale(1);
            @include screen-tablet-landscape-and-bigger {
                transform: scale(1.035);
            }
        }
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.carousel::-webkit-scrollbar {
    display: none;
}