/*----------------------------------*\
  #COLORS
\*----------------------------------*/

// Color palette

$color-red: #ff3000;
$color-secondary: #333333;
$color-tertiary: #C0C0C0;

$color-black: #000000;
$color-grey-dark: #333333;
$color-grey-darker: #2c2a38;
$color-grey: #C0C0C0;
$color-grey-light: #C0C0C0;

$color-white: #ffffff;
$color-off-white: #C0C0C0;

$color-darkgreen: #082300;
$color-darkred: #4a0000;
$color-darkblue: #070323;
$color-yellow: #BCFE12;

$color-middlegrey: #727272;

$color-2025-blue: #382DFF;





// Core color settings

$color-background-html: $color-white;
$color-background-primary: $color-white;

$color-text-header: $color-grey-dark;
$color-text: $color-grey-dark;

$color-divider-line: $color-grey-light;

$color-form-placeholder: $color-grey;
$color-form-focus: $color-red;

// Social color palette

$color-social-twitter: #00aced;
$color-social-facebook: #3b5998;
$color-social-googleplus: #dd4b39;
$color-social-pinterest: #cb2027;
$color-social-linkedin: #007bb6;
$color-social-youtube: #bb0000;
$color-social-vimeo: #aad450;
$color-social-instagram: #517fa4;
$color-social-flickr: #ff0084;
$color-social-dribbble: #ea4c89;
$color-social-whatsapp: #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map: (
  'twitter': $color-social-twitter,
  'facebook': $color-social-facebook,
  'linkedin': $color-social-linkedin,
  'youtube': $color-social-youtube
);
