.content {
    .takeover-parts {
        color: $color-yellow;
        .takeover-part-slider {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            padding: 0 $gutter-vertical-big $gutter-vertical-bigger/1.2 $gutter-vertical-small;
            flex-direction: column;
            margin-bottom: $gutter-vertical-regular;
            @include screen-tablet-landscape-and-bigger {
              flex-direction: row;
              margin-bottom: $gutter-vertical-bigger;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            .menu--is-active & {
              flex-direction: column;
              .takeover-part-featured-wrapper {
                margin-top: $gutter-vertical-insane;
              }
              .takeover-part-info {
                position: absolute;
                .takeover-part-info-sticky {
                  max-width: 100%;
                }
                .takeover-part-info-timing {
                  width: 100%;
                }
              }
            }
    
          .takeover-part-info {
            display: flex;
            flex-direction: column;
            height: 30vh;
            max-height: 30vh;
            position: absolute;
            @include screen-tablet-landscape-and-bigger {
              position: inherit;
              height: 60vh;
              max-height: 60vh;
            }
    
            h5, span.lead-caption {
              text-align: left;
              color: $color-yellow;
            }
            h5 {
              @include text-style-desktop-m-medium;
            }
            h4.lead {
              text-align: left;
              // margin-top: $gutter-vertical-micro*0.2;
              margin-bottom: $gutter-vertical-regular;
            }
            div.takeover-part-info-timing {
              display: flex;
              color: $color-grey;
              width: 100%;
              padding: 0 $gutter-vertical-regular 0 0;
              flex-wrap: wrap;
              row-gap: $gutter-vertical-micro;
              @include screen-tablet-landscape-and-bigger {
                row-gap: $gutter-vertical-small*1.2/2;
                width: 60%;
              }
    
              @include text-style-desktop-m-medium;
              span {
                font-variant-numeric: tabular-nums;
                margin-bottom: 0;
                display: inline-flex;
                padding: 0 $gutter-vertical-micro*1.6 0 0;
                @include screen-tablet-landscape-and-bigger {
                  padding: 0 $gutter-vertical-small 0 0;
                }
              }
            }
            > span {
              margin-bottom: $gutter-vertical-small*1.2;
            }
            .takeover-part-info-sticky {
              position: absolute;
              a {
                text-decoration: none;
                &:hover {
                  transition: color $color-transition-timing linear;
                  color: $color-grey;
                }
              }
            }
            .takeover-part-info-no_sticky {
              height: 100%;
              display: flex;
              flex-direction: column;
              padding-top: $gutter-vertical-micro*6;
              @include screen-tablet-landscape-and-bigger {
                padding-top: $gutter-vertical-micro*10;
              }
              h5 {
                margin-top: $gutter-vertical-small;
                @include screen-tablet-landscape-and-bigger {
                  margin-top: $gutter-vertical-regular;
                }
              }
            }
            .menu--is-active & {
              flex-basis: 60%;
              max-width: 60%;
            }
          }
          .takeover-part-featured-wrapper {
            display: flex;
            flex-direction: row;
            margin-top: $gutter-vertical-huge + $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
              margin-top: 0;
            }
            .takeover-part-featured {
              z-index: 1;
              .takeover-part-feature {
                .takeover-part-feature-images {
                  display: flex;
                  flex-direction: row;
                  gap: $gutter-vertical-small;
                  figure.takeover-part-feature-image {
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
                    img {
                      aspect-ratio: 9/16;
                      width: auto;
                      height: 40vh;
                      max-height: 40vh;
                      @include screen-tablet-landscape-and-bigger {
                        height: 60vh;
                        max-height: 60vh;
                      }
                    }
                  }
                }
                .takeover-part-feature-caption {
                  margin-top: $gutter-vertical-small;
                  h3, h4 {
                    text-align: left;
                  }
                  h3 {
                    @include text-style-desktop-m-super;
                  }
                  h4 {
                    @include text-style-desktop-m-medium;
                  }
      
                }
              }
              & + .takeover-part-featured {
                margin-left: $gutter-vertical-small;
                @include screen-tablet-landscape-and-bigger {
                  margin-left: $gutter-vertical-big;
                }
              }
              &:last-of-type {
                padding-right: $gutter-vertical-regular;
              }
            }
          }
          & + .takeover-part-slider {
            padding: $gutter-vertical-bigger/1.2 $gutter-vertical-big $gutter-vertical-bigger/1.2 $gutter-vertical-small;
          }
          &:last-of-type {
            border-bottom: 0;
            padding: $gutter-vertical-regular $gutter-vertical-small 0 $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
              padding: $gutter-vertical-bigger/1.2 $gutter-vertical-big 0 $gutter-vertical-small;
            }
          }
        }
        &::before {
          content : "";
          display: block;
          height: 2px;
          width: calc(100% - #{$gutter-vertical-regular});
          background-color: $color-yellow;
          margin: 0 0 0 $gutter-vertical-small;
        }
    }
}