.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  margin-top: $global-spacing-md;
  margin-bottom: $global-spacing-xlg * 2;
}

.subtitle {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 20px;
}
