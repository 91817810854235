.partners-grid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: $gutter-vertical-bigger;
    margin-bottom: 0;
    row-gap: $gutter-vertical-micro*6;
    
    @include screen-tablet-landscape-and-bigger {
        row-gap: $gutter-vertical-bigger;
        margin-top: $gutter-vertical-bigger;
    }
    &:first-of-type {
        margin-top: $gutter-vertical-bigger;
    }

    .partner {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include text-style-desktop-m-medium;

        @include screen-laptop-and-bigger {
            flex-direction: row;
        }

        .partner-logo {
            display: flex;
            height: $gutter-vertical-huge;
            padding-bottom: $gutter-vertical-micro;
            width: 100%;
            @include screen-tablet-landscape-and-bigger {
                height: $gutter-vertical-huge;
                padding-bottom: 0;
                width: 50%;
                .menu--is-active & {
                    width: 80%;
                  }
            }
            a {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                svg {
                    height: $gutter-vertical-insane*1.5;
                    g, path, polygon, rect {
                        fill: $color-darkblue;
                        transition: fill $interaction-timing linear;
                    }
                }
                &:hover {
                    svg {
                        g, path, polygon, rect {
                            fill: $color-grey;
                        }
                    }
                }
            }
        }
        .partner-description {
            text-align: center;
            width: 100%;
            @include screen-laptop-and-bigger {
                text-align: left;
                width: 50%;
            }
            p {
                margin: 0;
            }
        }
        &:last-of-type {
            margin-bottom: $gutter-vertical-huge;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-bigger;
            }
            
        }
        &.big-logo {
            .partner-logo {
                height: $gutter-vertical-bigger;
                padding-bottom: 0;
                @include screen-tablet-landscape-and-bigger {
                    height: $gutter-vertical-huge;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.menu--is-active {
    .partner {
        flex-direction: column;
        .partner-description {
            width: 100%;
            text-align: center;
        }
    }
}

.menu--is-active {
    .big-logo {
        .partner-logo {
            a {
                padding: 0 !important;
            }
        }
    }
}