.content {
  .category-parts {
    color: $color-yellow;
    .category-part-slider {
      display: flex;
      padding: 0 $gutter-vertical-big $gutter-vertical-bigger/1.2 $gutter-vertical-small;
      overflow-y: hidden;
      overflow-x: scroll;
      margin-bottom: $gutter-vertical-regular;
      flex-direction: column;
      @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-bigger;
        flex-direction: row;
      }
      -ms-overflow-style: none;
        scrollbar-width: none;
  
      .category-part-featured-wrapper {
        margin-top: $gutter-vertical-huge+$gutter-vertical-small;
        @include screen-tablet-landscape-and-bigger {
          margin-top: 0;
        }
        display: flex;
        flex-direction: row;
      }
      .category-part-info {
        display: flex;
        flex-direction: column;
        height: 60vh;
        max-height: 60vh;
        max-width: 80vh;
        margin-bottom: $gutter-vertical-regular;
        position: absolute;
        @include screen-tablet-landscape-and-bigger {
          position: inherit;
          margin-bottom: 0;
        }
  
        h5, span.lead-caption {
          text-align: left;
          color: $color-yellow;
        }
        h5 {
          @include text-style-desktop-m-medium;
        }
        h4.lead {
          text-align: left;
          margin-top: $gutter-vertical-micro*0.5;
          margin-bottom: $gutter-vertical-regular;
          @include screen-tablet-landscape-and-bigger {
            margin-top: $gutter-vertical-micro;
          }
        }
        div.category-part-info-timing {
          display: flex;
          color: $color-grey;
          padding: 0 $gutter-vertical-regular 0 0;
          flex-wrap: wrap;
          row-gap: $gutter-vertical-small*1.2/2;
          width: 100%;
          white-space: nowrap;
          @include screen-tablet-landscape-and-bigger {
            width: 100%;
          }
  
          @include text-style-desktop-m-medium;
          span {
            font-variant-numeric: tabular-nums;
            padding: 0 $gutter-vertical-small 0 0;
            margin-bottom: 0;
            display: inline-flex;
          }
        }
        > span {
          margin-bottom: $gutter-vertical-small*1.2;
        }
        .category-part-info-sticky {
          position: relative;
          @include screen-tablet-landscape-and-bigger {
            position: absolute;
            transition: margin $color-transition-timing linear;
          }
        }
        .category-part-info-no_sticky {
          height: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 0;
          @include screen-tablet-landscape-and-bigger {
            margin-top: $gutter-vertical-bigger;
          }
          span.lead-caption {
            margin-bottom: $gutter-vertical-micro;
            white-space: nowrap;
          }
          h5 {
            margin-top: $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
              margin-top: $gutter-vertical-regular;
            }
          }
        }
        .menu--is-active & {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
      .category-part-featured {
        z-index: 1;
        text-decoration: none;
        h4, h3 {
          transition: color $color-transition-timing linear;
        }
        &:hover {
          h4, h3 {
            color: $color-grey;
          }
          .category-part-feature {
            .category-part-feature-images {
              transform: scale(1.025);
            }
          }
        }
        .category-part-feature {
          .category-part-feature-images {
            display: flex;
            flex-direction: row;
            gap: $gutter-vertical-small;
            transition: transform $interaction-timing $ease-out-quart;
            transform-origin: bottom;
            figure.category-part-feature-image {
                background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
              img {
                aspect-ratio: 9/16;
                width: auto;
                height: 40vh;
                max-height: 40vh;
                @include screen-tablet-landscape-and-bigger {
                  height: 60vh;
                  max-height: 60vh;
                }
              }
            }
          }
          .category-part-feature-caption {
            margin-top: $gutter-vertical-small;
            h3, h4 {
              text-align: left;
            }
            h3 {
              text-decoration: none;
              display: block;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              @include text-style-desktop-m-super;
            }
            h4 {
              @include text-style-desktop-m-medium;
            }
          }
        }
        & + .category-part-featured {
          margin-left: $gutter-vertical-small;
          @include screen-tablet-landscape-and-bigger {
            margin-left: $gutter-vertical-regular;
          }
          @include screen-laptop-and-bigger {
            margin-left: $gutter-vertical-big;
          }
        }
        &:last-of-type {
          padding-right: $gutter-vertical-regular;
        }
      }
      & + .category-part-slider {
        padding: $gutter-vertical-regular $gutter-vertical-big $gutter-vertical-bigger/1.2 $gutter-vertical-small;
      }
      &:last-of-type {
        padding: $gutter-vertical-regular 0 0 $gutter-vertical-small;
        @include screen-tablet-landscape-and-bigger {
          padding: $gutter-vertical-bigger $gutter-vertical-big 0 $gutter-vertical-small;
        }
      }
    }
    &::before {
      content : "";
      display: block;
      height: 2px;
      width: calc(100% - #{$gutter-vertical-regular});
      background-color: $color-yellow;
      margin: 0 0 0 $gutter-vertical-small;
    }
    &.no-line {
      &::before {
        content : "";
        display: block;
        height: 0px;
        width: calc(100% - #{$gutter-vertical-regular});
        background-color: $color-yellow;
        margin: 0 0 0 $gutter-vertical-small;
      }
    }
    &:first-of-type {
      .category-part-slider {
        padding: 0 $gutter-vertical-big 0 $gutter-vertical-small;
      }
    }
  }
}