.locations-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $gutter-vertical-micro*10;
    margin-bottom: 0;
    @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-big;
        margin-top: $gutter-vertical-bigger;
    }
    
	.location {
        display: flex;
        flex: 0 1 100%;
        flex-flow: column;
        align-items: center;
        padding: 0 0;
        margin-bottom: $gutter-vertical-micro*8;
        @include text-style-desktop-m-medium;
        // @include screen-mobile-plus-and-bigger {
        //     flex: 0 1 33.33333333%;
        //     padding: 0 $gutter-vertical-small;
        // }
        @include screen-tablet-landscape-and-bigger {
            flex: 0 1 33.33333333%;
            padding: 0 $gutter-vertical-big;
        }
        &:last-of-type{
            margin-bottom: 0;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-micro*8;
            }
        }
        span.lead-caption {
            margin-bottom: $gutter-vertical-micro;
        }
        h4 {
            margin-bottom: $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-regular;
            }
        }
        .location-description {
            text-align: center;
            flex-grow: 1;
            max-width: 100%;
            @include screen-mobile-plus-and-bigger {
                max-width: 85%;
            }
            @include screen-tablet-landscape-and-bigger {
                max-width: 100%;
            }
            p {
                margin: 0;
            }
        }
        button {
            margin-top: $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
                margin-top: $gutter-vertical-regular;
            }
        }
        .menu--is-active & {
            padding: 0 $gutter-vertical-small;
            flex: 0 1 65%;
        }
	}
}

.svg-illustration-wrapper {
    margin-top: $gutter-vertical-big;
    float: left;
    &.regular-top-margin {
        margin-top: $gutter-vertical-regular;
    }
    &.amsterdam-station {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: $gutter-vertical-regular;
        svg {
            max-width: 70vw;
            max-height: 70vh;
            width: 100%;
            height: 100%;
        }
    }
}
.content {
    .all-locations {
        h2 {
            margin-bottom: $gutter-vertical-big;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-bigger;
            }
        }
        margin-top: $gutter-vertical-bigger;
        @include screen-tablet-landscape-and-bigger {
            margin-top: $gutter-vertical-regular;
        }
        .list-left_align {
            padding: 0 $gutter-vertical-small !important;
            @include screen-tablet-landscape-and-bigger {
                padding: 0 $gutter-vertical-micro 0 $gutter-vertical-small !important;
            }
            p.lead {
                @include text-style-desktop-sl-medium;
            }
        }
        .selected-location {
            span.lead-caption {
                color: $color-yellow;
            }
            p.lead {
                color: $color-grey;
            }
            & + .selected-location {
                margin-top: $gutter-vertical-regular;
                @include screen-tablet-landscape-and-bigger {
                    margin-top: $gutter-vertical-big;
                }
            }
        }
    }
}

section.dutch-map {
    flex-direction: column;
    margin-top: $gutter-vertical-regular;
    justify-content: space-evenly;
    @include screen-tablet-landscape-and-bigger {
        margin-top: 0;
        flex-direction: row;
    }
    .dutch-map-map {
        svg {
            height: auto;
            width: 100%;
            max-height: 80vh;
            g {
                // transform-box: fill-box;
                // transform-origin: center;
                // transform: scale(0);
                // transition: transform 1s;
            }
        }
        &.aos-animate {
            svg {
                g {
                    // transform: scale(1);
                }
            }
        }
    }
    .dutch-map-caption {

    }
}