.specs {
    display: flex;
    color: $color-grey;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom:$gutter-vertical-big;
    margin-top:$gutter-vertical-small;
    row-gap: 4px;
    @include text-style-desktop-l-medium;

    @include screen-tablet-landscape-and-bigger {
        row-gap: 0px;
        margin-bottom:$gutter-vertical-huge;
        width:100%;
	    display:flex;
        align-content: space-between;
        justify-content: space-between;
    }

	.spec {
		flex-grow: 1;
        flex-basis: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 1 auto;

        @include screen-tablet-landscape-and-bigger {
            &:nth-child(2) {
                text-align:center;
            }

            &:nth-child(3) {
                text-align:right;
            }
        }
	}
}

.trailer-container {
    position:relative;
    overflow:hidden;
    @include screen-tablet-landscape-and-bigger {
    }

    &.trailer-is-active {
        .play-link,
        .figure-video,
        .primary-button,
        h3.lead,
        span.lead-caption,
        img {
            opacity: 0;
            pointer-events: none;
        }
        .primary-button-wrapper {
            pointer-events: none;
        }
        .vimeo-container {
            opacity: 1;
        }
    }

    h3 {
        br {
            display: none;
            @include screen-tablet-portrait-and-bigger {
                display: block;
            }
        }
    }

    .play-link {
        position:absolute;
        z-index:2;
        color:$color-yellow;
        @include text-style-desktop-xl-medium;
        line-height:.9;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        height:100%;
        cursor:pointer;
        background:rgba(0, 0, 0, 0.2);
        padding:15px;

        &:hover{
            color: $color-darkblue;
        }

        svg {
            width:$gutter-vertical-regular;
            transition: transform $interaction-timing $ease-in-out-quart;

            @include screen-tablet-landscape-and-bigger {
                width:70px;
            }

            g {
                fill:$color-grey;
                stroke:$color-grey;
                transition: color $color-transition-timing linear;
            }

            &:hover {
                transform:scale(1.1);

                g {
                    fill:$color-yellow;
                    stroke:$color-yellow;
                }
            }
        }
    }

    .primary-button-wrapper {
        display: flex;
        position: absolute;
        z-index: 100;
        align-items: center;
        width: calc(100% - #{$gutter-vertical-regular});
        height: 100%;
        margin: 0 $gutter-vertical-small;
        flex-direction: column;
        justify-content: center;
        .primary-button {
        }
    }

    .vimeo-container {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 95vh;
        min-height: 50vh;
        overflow: hidden;
    }

    .background-img {
        max-height: 95vh;
        min-height: 50vh;
        height: 50vh;
        @include screen-tablet-portrait-and-bigger {
            height: auto;
        }
        object-fit: cover;
        display: flex;
        align-items: center;
		.figure-video {
			width:100%;
		}
    }

    img,
    .figure-video {
        pointer-events:none;
        display: flex;
        height: 100%;
    }
}

.vimeo-fullscreen {
    height: 100%;
    iframe,
    object,
    embed {
        width: 100%;
        height: 100%;
        max-height: 95vh;
    }
}

.vimeo {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.submit {
    margin-bottom:0;
    padding-top:50px;

	p {
		strong {
			color:$color-red;
		}
    }
}
