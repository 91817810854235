.content {
	.accordion-wrapper {
		border-top: 2px $color-darkblue solid;
		padding: $gutter-vertical-small 0 0 0;
		@include screen-tablet-landscape-and-bigger {
		  padding: $gutter-vertical-micro*3.2 0 0 0;
		}
		.accordion-question {
		  color: $color-darkblue;
		  transition: color $interaction-timing $ease-out-quart;
		  cursor: pointer;
		  width: 100%;
		  text-align: left;
		  border: none;
		  outline: none;
		  display: flex;
		  justify-content: space-between;
		  padding: 0 0 $gutter-vertical-small 0;
		  @include screen-tablet-landscape-and-bigger {
			padding: 0 0 $gutter-vertical-micro*3.2 0;
		  }
		  h5 {
			@include text-style-desktop-l-medium;
		  }
		  svg {
			align-self: center;
			transition: transform $interaction-slow $ease-out-quart;
			margin: 0 $gutter-vertical-micro*.5 0 0;
			fill {
			  transition: color $interaction-timing $ease-out-quart;
			}
		  }
		  &.active {
			& + .accordion-content {
			  padding: 0 0 $gutter-vertical-micro*.5 0;
			  margin: 0 0 $gutter-vertical-small 0;
			  @include screen-tablet-landscape-and-bigger {
				margin: 0 0 $gutter-vertical-micro*3.2 0;
			  }
			}
		  }
		}
		.accordion-content {
		  color: $color-grey;
		  max-height: 0;
		  overflow: hidden;
		  transition: max-height $interaction-timing $ease-out-quart, padding 0.0s $ease-out-quart, margin 0.1s $ease-out-quart;
		  p {
			@include text-style-desktop-xl-medium;
		  }
		}
		&.open {
		  .accordion-question {
			color: $color-grey;
			svg {
			  transform: rotateZ(180deg);
			  path {
				fill: $color-grey;
			  }
			}
		  }
		  .accordion-content {
			color: $color-grey;
		  }
		}
		&:last-of-type {
	
			border-bottom: 2px $color-darkblue solid;
		}
	}
}