.theme-darkblue {
	background-color: $color-darkblue;
	transition: background-color $interaction-slow $ease-out-quart;

    .menu {
		background-color: $color-2025-blue;

        .menu-close {
            color: $color-darkblue;
        }
        li {
            color:$color-darkblue;
            .active {
                color:$color-2025-blue
            }

            &:hover {
                a {
                    color:$color-off-white;
                }
            }
        }

        .secondary-menu-wrapper {
            color: $color-darkblue;
			
            .secondary-menu{
                li {
                    &:hover {
                        a {
                            color:$color-darkblue;
                        }
                    }
                }
            }
        }
    }

	ul.huge-list.darkblue li a{
		color: $color-off-white;
	}

    .h2 {
        color: $color-off-white;
    }

    .location-description {
        color: $color-off-white;

    }

    .header__logo {
        &:hover {
            color: $color-off-white;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color: $color-off-white;
            }
        }
    }

	.content {
		h2 {
			color:  $color-off-white;
		}
		h3 {
			color: $color-off-white;
		}
		h4 {
			color: $color-off-white;
		}
		h5 {
			color: $color-off-white;
		}
        p.lead {
            color: $color-off-white;
        }
        .caption {
            color: $color-off-white;
        }
        .lead-caption {
            color:  $color-off-white;
        }
        .BGImage {
            img {
                background-color:  $color-off-white;
            }
            figure {
                video {
                    // background-color: $color-grey;
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%);
                }
            }
        }
	}

    strong{
        color: $color-darkblue;
    }
    .blue-section {
        color: $color-off-white;
        span.lead-caption{
            color: $color-off-white;
        }
    }
    .whole-footer {
	    background-color: $color-darkblue;
        .footer-row {
            // color: $color-off-white;
            border-color: $color-off-white;
            a {
                color: $color-off-white;
                &:hover {
                    color: $color-grey;
                }
            }
        }
    }
    .page-header {
        .lead-caption {
            color: $color-off-white;
        }
    }

	.primary-button{
		border-color:  $color-off-white;
		color:  $color-off-white;
		a{
			color: $color-off-white;
		}
	}

    .partners-grid{
        .partner{
            .partner-logo{
                a {
                    svg {
                        g, path {
                            fill: $color-off-white;
                        }
                    }
                }
            }
            .partner-description{
                p{
                    color: $color-off-white;
                }
            }
        }
    }

    .about-content{
        p.paragraph{
            color: $color-off-white;
        }
    }

    .past-page-special {

        .trailer-container .play-link{
            color: $color-red;
            &:hover{
                color: $color-grey;
            }
        }
        h2{
            color:$color-red;
        }
        p.large{
            color: $color-grey;
            strong{
                color: $color-red;
            }
        }
    }

}

.theme-yellow {
	background-color: $color-yellow;
	transition: background-color $interaction-slow $ease-out-quart;

	.menu {
		background-color: $color-grey;

        .menu-close {
            color: $color-grey;
        }
        li {
            color:$color-darkblue;
            .active {
                color:$color-darkblue;
            }
            &:hover {
                a {
                    color:$color-yellow;
                }
            }
        }
        .secondary-menu-wrapper {
            color: $color-darkblue;
            .secondary-menu{
                li {
                    &:hover {
                        a {
                            color:$color-yellow;
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        &:hover {
            color:$color-darkblue;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-darkblue;
            }
        }
    }

	.content {
		h2 {
			color:$color-darkblue;
		}
        .lead-caption {
            color: $color-grey;
        }
        .BGImage {
            img {
                background-color: $color-darkblue;
            }
            figure {
                video {
                    background-color: $color-darkblue;
                }
            }
        }
	}

    strong{
        color: $color-darkblue;
    }
    .blue-section {
        color: $color-yellow;
        span.lead-caption{
            color: $color-yellow;
        }
        h2 {
            color: $color-yellow;
        }
    }
    .whole-footer {
        .footer-row {
            a {
                color: $color-darkblue;
                &:hover {
                    color: $color-grey;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                    fill: $color-yellow;
                }
            }
        }
    }
}


.theme-2025 {
	background-color: $color-2025-blue;
	transition: background-color $interaction-slow $ease-out-quart;
    color: $color-off-white;

	.menu {
		background-color: $color-darkblue;

        .menu-close {
            color:$color-2025-blue;
        }
        li {
            color:$color-off-white;
            .active {
                color:$color-2025-blue;
            }
        }
        ul.primary-menu {
            a {
                &:hover {
                    color: $color-2025-blue;
                }
            }
        }
    }

	.secondary-menu-wrapper {
		span{
			color:$color-grey !important;
		}
		.secondary-menu{
			
			li {
				&:hover {
					a {
						color:$color-2025-blue;
					}
				}
			}
		}
	}

	.menu-close {
		
		svg {
		  path {
			transition: fill $color-transition-timing linear;
			fill: $color-off-white;
		  }
		}

		&:hover{
			svg {
				path {
				  transition: fill $color-transition-timing linear;
				  fill: $color-2025-blue;
				}
			  }
		}

	}
  

    .header__logo {
        &:hover {
            color:$color-darkblue;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-darkblue;
            }
        }
    }

	.content {
        h1 {
            color:$color-off-white;
        }
		h2 {
			color:$color-off-white;
            br {
                display: none;
                @include screen-tablet-landscape-and-bigger {
                    display: table;
                }
            }

		}
        .lead-title-huge {
            color: $color-off-white;
        }
        .lead-caption {
            color: $color-off-white;
        }
        .lead-BGImage-landscape {
            .lead-caption,
			.lead {
                color: $color-off-white;
            }
        }
        .lead-BGImage {
            .lead-caption-wrapper {
                span {
                    color: $color-off-white;
                }
                color: $color-off-white;
            }
        }
        .BGImage {
            img {
                background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
            }
            figure {
                video {
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
                }
            }
        }
	}


    strong{
        color: $color-grey;
    }
    .whole-footer {
        .footer-row {
            border-color: $color-grey;
            a {
                color: $color-grey;
                &:hover {
                    color: $color-off-white;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                    fill: $color-off-white;
                }
            }
        }
    }
}

.theme-black {
	background-color: $color-black;
	transition: background-color $interaction-slow $ease-out-quart;
    color: $color-white;

	.menu {
		background-color: $color-grey;

        .menu-close {
            color: $color-grey;
        }
        li {
            color:$color-black;
            .active {
                color:$color-white;
            }
        }
        ul.primary-menu {
            a {
                &:hover {
                    color: $color-white;
                }
            }
        }
    }

    .header__logo {
        &:hover {
            color:$color-white;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-white;
            }
        }
    }

	.content {
        h1 {
            color:$color-white;
        }
		h2 {
			color:$color-white;
            br {
                display: none;
                @include screen-tablet-landscape-and-bigger {
                    display: table;
                }
            }

		}
        .lead-title-huge {
            color: $color-white;
        }
        .lead-caption {
            color: $color-grey;
        }
        .lead-BGImage-landscape {
            .lead-caption {
                color: $color-white;
            }
        }
        .lead-BGImage {
            .lead-caption-wrapper {
                span {
                    color: $color-white;
                }
                color: $color-white;
            }
        }
        .BGImage {
            img {
                background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
            }
            figure {
                video {
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
                }
            }
        }
	}

    strong{
        color: $color-grey;
    }
    .whole-footer {
        .footer-row {
            border-color: $color-grey;
            a {
                color: $color-grey;
                &:hover {
                    color: $color-white;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }
}

.theme-darkred {
	background-color: $color-darkblue;
	transition: background-color $interaction-slow $ease-out-quart;
    color: $color-red;

	.menu {
		background-color: $color-grey;

        .menu-close {
            color: $color-grey;
        }
        li {
            color:$color-darkblue;
        }
        ul.primary-menu {
            a {
                &:hover {
                    color: $color-red;
                }
            }
        }
        .secondary-menu-wrapper {
            color: $color-darkblue;
            .secondary-menu{
                li {
                    &:hover {
                        a {
                            color:$color-red;
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        &:hover {
            color:$color-red;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-red;
            }
        }
    }

	.content {
		h2 {
			color:$color-red;
		}
        .lead-title-huge {
            color: $color-red;
        }
        .lead-caption {
            color: $color-grey;
        }
        .lead-BGImage {
            .lead-caption-wrapper {
                span {
                    color: $color-grey;
                }
                color: $color-red;
            }
        }
        .BGImage {
            img {
                background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
            }
            figure {
                video {
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
                }
            }
        }
	}

    strong{
        color: $color-grey;
    }
    .whole-footer {
        .footer-row {
            border-color: $color-grey;
            a {
                color: $color-grey;
                &:hover {
                    color: $color-white;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                fill: $color-red;
                }
            }
        }
    }
}

.theme-darkyellow {
	background-color: $color-darkblue;
	transition: background-color $interaction-slow $ease-out-quart;
    color: $color-yellow;

	.menu {
		background-color: $color-grey;

        .menu-close {
            color: $color-grey;
        }
        li {
            color:$color-darkblue;
        }
        ul.primary-menu {
            a {
                &:hover {
                    color: $color-yellow;
                }
            }
        }
        .secondary-menu-wrapper {
            color: $color-darkblue;
            .secondary-menu{
                li {
                    &:hover {
                        a {
                            color:$color-yellow;
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        &:hover {
            color:$color-yellow;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-yellow;
            }
        }
    }

	.content {
		h2 {
			color:$color-yellow;
		}
        .lead-title-huge {
            color: $color-yellow;
        }
        .lead-caption {
            color: $color-grey;
        }
        .lead-BGImage {
            .lead-caption-wrapper {
                span {
                    color: $color-grey;
                }
                color: $color-yellow;
            }
        }
        .BGImage {
            img {
                background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
            }
            figure {
                video {
                    background: linear-gradient(0deg, rgba(255,255,255,0.125) 0%, rgba(255,255,255,0.01) 33%, rgba(255,255,255,0.125) 100%)
                }
            }
        }
	}

    strong{
        color: $color-grey;
    }
    .whole-footer {
        .footer-row {
            border-color: $color-grey;
            a {
                color: $color-grey;
                &:hover {
                    color: $color-white;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                fill: $color-yellow;
                }
            }
        }
    }
}

.theme-red {
	background-color: $color-red;
	transition: background-color $interaction-slow $ease-out-quart;

	.menu {
		background-color: $color-grey;

        .menu-close {
            color: $color-grey;
        }
        li {
            color:$color-darkblue;
            .active {
                color:$color-darkblue;
            }
            &:hover {
                a {
                    color:$color-red;
                }
            }
        }

        .secondary-menu-wrapper {
            color: $color-darkblue;
            .secondary-menu{
                li {
                    &:hover {
                        a {
                            color:$color-red;
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        &:hover {
            color:$color-darkblue;
        }
    }

    @include screen-tablet-landscape-and-bigger {
        .menu-link {
            &:hover {
                color:$color-darkblue;
            }
        }
    }

	.content {
		h2 {
			color:$color-darkblue;
		}
        .lead-caption {
            color: $color-grey;
        }
        .BGImage {
            img {
                background-color: $color-darkblue;
            }
            figure {
                video {
                    background-color: $color-darkblue;
                }
            }
        }
	}

    strong{
        color: $color-darkblue;
    }
    .whole-footer {
        .footer-row {
            a {
                color: $color-darkblue;
                &:hover {
                    color: $color-grey;
                }
            }
        }
    }
    .menu-close {
        &:hover {
            svg {
                path {
                    fill: $color-red;
                }
            }
        }
    }
}