.content {
  .exhibition_wrapper {
    text-decoration: none;
    &::before {
      content : "";
      display: block;
      height: 2px;
      width: calc(100% - #{$gutter-vertical-regular});
      background-color: $color-darkblue;
      opacity: 0;
      margin: -2px 0 0 $gutter-vertical-small;
      transition: opacity $color-transition-timing linear;
    }
    &::after {
      content : "";
      display: block;
      height: 2px;
      width: calc(100% - #{$gutter-vertical-regular});
      background-color: $color-grey;
      margin: 0 0 0 $gutter-vertical-small;
      transition: background-color $interaction-timing linear;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
      &::after {
        background-color: $color-darkblue;
      }
    }
    .exhibition_wrapper-border {
      width: 100%;
      margin: 0 $gutter-vertical-small;
      padding: $gutter-vertical-small*1.2 0;
      .exhibition {
        .exhibition-theme {
          display: flex;
          > span {
            width: 25%;
            align-self: center;
            @include screen-laptop-and-bigger {
              width: 15%;
            }
            @include text-style-desktop-m-medium;
          }
          &.bold {
            > span {
              @include text-style-desktop-m-super;
            }
          }
          > div {
            display: flex;
            width: 100%;
            margin: 0 auto 0 0;
            justify-content: space-between;
            @include screen-laptop-and-bigger {
              width: 55%;
            }
            h4 {
              text-align: left;
              @include text-style-desktop-xl-medium;
              .menu--is-active & {
                @include text-style-desktop-l-medium;
              }
            }
            span {
              align-self: center;
              white-space: pre;
              @include text-style-desktop-m-medium;
            }
          }
          svg {
            display: flex;
            align-self: center;
            display: none;
            transition: transform $interaction-timing $ease-out-quart;
            @include screen-laptop-and-bigger {
              display: inline;
            }
            path {
              margin: 0 0 0 auto;
            }
          }
        }
        .exhibition-takeover {
          display: flex;
          color: $color-grey;
          margin-right: 0;
          @include screen-laptop-and-bigger {
            margin-left: 15%;
          }
          @include text-style-desktop-m-medium;
          p {
            margin: 0 auto 0 0;
          }
          svg {
            align-self: center;
            transition: fill $interaction-timing $ease-out-quart, transform $interaction-timing $ease-out-quart;
            display: none;
            @include screen-laptop-and-bigger {
              display: inline;
            }
            path {
              fill: $color-grey;
            }
          }
        }
      }
    }
    transition: background-color $color-transition-timing linear, color $color-transition-timing linear;
    &.exhibition_wrapper-theme {
      &:hover {
        background-color: $color-darkblue;
        color: $color-yellow;
        svg {
          align-self: center;
          transform: translate($gutter-vertical-micro);
          path {
            fill: $color-yellow;
          }
        }
      }
    }
    &.exhibition_wrapper-takeover {
      .exhibition_wrapper-border {
        padding: $gutter-vertical-micro 0;
      }
      &:hover {
        background-color: $color-grey;
        .exhibition-takeover {
          color: $color-yellow;
          svg {
            transform: translate($gutter-vertical-micro);
            path {
              fill: $color-yellow;
            }
          }
        }
        &::before {
          background-color: $color-grey;
        }
        &::after {
          background-color: $color-grey;
        }
      }
    }
    margin: -2px 0 0 0;
    padding: 2px 0 0 0;
  }
}