// sass-lint:disable no-vendor-prefixes, brace-style, no-important

//
//    A collection of some helper classes for toggling the visibility of elements
//
//    e.g.
//
//    <div class="hidden-xs">
//        This element will be hidden on screen size xs
//    </div>
//

@each $visibility-modifier, $visibility-media in $breakpoint-list {
  .u-visible-#{$visibility-modifier},
  .u-visible-#{$visibility-modifier}-block,
  .u-visible-#{$visibility-modifier}-table-cell,
  .u-visible-#{$visibility-modifier}-inline-block,
  .u-visible-#{$visibility-modifier}-inline {
    display: none !important;
  }

  @media #{$visibility-media} {
    .u-visible-#{$visibility-modifier},
    .u-visible-#{$visibility-modifier}-block {
      display: block !important;
    }

    .u-visible-#{$visibility-modifier}-table-cell {
      display: table-cell !important;
    }

    .u-visible-#{$visibility-modifier}-inline-block {
      display: inline-block !important;
    }

    .u-visible-#{$visibility-modifier}-inline {
      display: inline !important;
    }

    .u-hidden-#{$visibility-modifier} {
      display: none !important;
    }
  }
}

.u-sr-only {
  @include sr-only;
}

.u-hidden {
  display: none !important;
}
