.whole-footer {
	padding-bottom: $gutter-vertical-micro;
	&.blue {
	background-color: $color-darkblue;
		.footer-row {
			color: $color-yellow;
			border-color: $color-yellow;
		}
	}
}

.c-footer {
    @include text-style-desktop-m-medium;
	z-index:1;
	position:relative;

	.footer-row {
		border-top: 2px solid $color-darkblue;
		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 10px 0;
			
			li {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
			}
		}
		&:last-of-type {
			@include text-style-desktop-s-medium;
		}
	}
}