.megascreen {
	.page-header {
		margin-top: $gutter-vertical-regular;
	}
	.megascreen-nearby {
		display: flex;
		flex-direction: column;	
		span.lead-caption + .vertical-gallery {
			margin-top: 0;
		}
		span.lead-caption {
			color: $color-yellow;
			margin-bottom: $gutter-vertical-regular;
		};
	}
	.megascreen-featuring {
		.vertical-gallery-image {
			figure {
				img {
					aspect-ratio: auto;
					max-height: 50vh;
					max-width: 100%;
					width: auto;
					margin: 0 auto 20px auto;
					display: block;
				}
			}
		} 
	}
}

.content {
	.megascreens-wrapper {
		display: flex;
		flex-direction: column;	
		row-gap: $gutter-vertical-micro*1.5;
		@include screen-tablet-landscape-and-bigger{
			row-gap: $gutter-vertical-micro*3;
		}
		margin-top: $gutter-vertical-micro*10;
		margin-bottom: $gutter-vertical-micro;

		.menu--is-active & {
			flex-basis: 80%;
			max-width: 80%;
			right: -10%;
		}
	}

	.megascreen-shape {
		display: block;
		position: relative;
		height: auto;
		width: auto;
		margin: auto;
		max-height: 60vh;
		@include screen-tablet-landscape-and-bigger{
			max-height: 90vh;
		}
		.megascreen-solid_fill {
			display: flex;
			position: absolute;
			width: 100%;
			height: 100%;
			transition: opacity $color-transition-timing $ease-out-quart;
		}
		lottie-player {
			transform: scale(1.01);
			// transition: transform $interaction-timing $ease-out-quart;
		}
		.BGImage {
			height: 100%;
			display: flex;
			width: 100%;
			position: absolute;
			overflow: hidden;
			figure {
				position: absolute;
				display: flex;
				width: 100%;
				height: 100%;
				video {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
		.megascreen-info {
			display: flex;
			z-index: 1;
			position: absolute;
			flex-direction: column;
			height: 100%;
			width: 100%;
			justify-content: center;
			align-content: center;
			h2,h3 {
				text-align: center;
				color: $color-yellow;
			}
			h2 {
				@include text-style-desktop-xl-super;
			}
			h3 {
				@include text-style-desktop-xl-medium;
			}
			.menu--is-active & {
				// background-color: red;
				h2 {
					@include text-style-desktop-l-super;
				}
				h3 {
					@include text-style-desktop-l-medium;
				}				
			}
		}

		// ——————————————————————————————————————————————

		&.blue-solid {
			max-height: fit-content;
			@include screen-tablet-landscape-and-bigger{
				max-height: 90vh;
			}
			a {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				max-height: unset;
				pointer-events: none;
				width: 100%;
				@include screen-tablet-landscape-and-bigger {
					pointer-events: all;
				}
				p {
					color: $color-yellow;
				}
				.megascreen-info {
					display: none;
					width: 75%;
					@include screen-tablet-landscape-and-bigger {
						display: flex;
						width: 50%;
					}
					.menu--is-active & {
						width: 70%;
						p.lead {
							@include text-style-desktop-l-medium;
						}
					}
				}
				lottie-player {
					transition: transform $interaction-timing $ease-out-quart;
				}
				&:hover {
					lottie-player {
						transform: scale(1.025);
					}
					button {
						background-color: $color-yellow;
						span {
							color: $color-darkblue;
						}
					}
				}
				& + .megascreen-info_mobile {
					margin-bottom: 0;
					margin-top: $gutter-vertical-small;
					@include screen-tablet-landscape-and-bigger{
						margin-top: $gutter-vertical-regular;
					}
					button {
						a {
							pointer-events: all;
						}
					}
				}
			}
			.megascreen-info_mobile {
				margin-bottom: $gutter-vertical-small;
				display: block;
				p {
					margin: auto;
					width: 80%;
				}
				@include screen-tablet-landscape-and-bigger {
					margin-bottom: 0;
					display: none;		
					p {
						width: 100%;
					}
				}
				& + a {
					margin-top: $gutter-vertical-small;
					@include screen-tablet-landscape-and-bigger {
						margin-top: 0;
					}
				}
			}
		}

		// ——————————————————————————————————————————————

		&.yellow-solid {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-bottom: $gutter-vertical-micro;
			max-height: 45vh;
			width: 100%;
			@include screen-tablet-landscape-and-bigger {
				max-height: 80vh;
				margin-bottom: $gutter-vertical-regular;
			}

			lottie-player {
				transition: transform $interaction-timing $ease-out-quart;
			}
			&:hover {
				lottie-player {
					transform: scale(1.04);
				}
			}
			.megascreen-info {
				h4, h5 {
					color: $color-grey;
					text-align: center;
				}
				h4 {
					@include text-style-desktop-xl-super;
				}
				h5 {
					@include text-style-desktop-xl-medium;
				}
			}
			.menu--is-active & {
				width: 60%;
			}
		}

		// ——————————————————————————————————————————————

		&.blue-video {
			max-height: 45vh;
			width: auto;
			// object-fit: contain;
			@include screen-tablet-landscape-and-bigger{
				max-height: 90vh;
			}
			.megascreen-solid_fill {
				background-color: $color-darkblue;
			}
			path {
				fill: $color-yellow
			}
			video {
				display: none;
				@include screen-mobile-plus-and-bigger {
					display: block;
				}
			}
			.megascreen-info {
				h2, h3 {
					color: $color-grey;
				}
			}
			&:hover {
				.megascreen-solid_fill {
					opacity: 1;
					@include screen-mobile-plus-and-bigger {
						opacity: 0;
					}
				}
				.megascreen-info {
					h2, h3 {
						color: $color-yellow;
					}
				}
			}
		}

		// ——————————————————————————————————————————————

		&.always-video {
			max-height: 40vh;
			@include screen-tablet-landscape-and-bigger {
				max-height: 70vh;
			}
		}
	}

// —————————————————————————————————————————————————————————

	.megascreen-wrapper {
		display: block;
		position: relative;
		height: auto;
		width: 100%;
		background-color: transparent;
		lottie-player {
			transition: transform $interaction-timing $ease-out-quart;
		}
		&:hover {
			lottie-player {
				transform: scale(1.025);
			}
		}
	}
	.megascreen-location {
		span.lead-caption {
			margin-bottom: $gutter-vertical-regular ;
		}
		p.lead {
			white-space: break-spaces;
		}
	}
}