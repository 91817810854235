.content {
    .talks-wrapper {
        .event-timeslot {
          border-top: 2px $color-darkblue solid;
          .event-timeslot-info {
            display: flex;
            flex-direction: column;
            row-gap: $gutter-vertical-small;
            margin-top: $gutter-vertical-micro*6;
            @include screen-tablet-portrait-and-bigger {
              margin-top: $gutter-vertical-big;
            }
            button {
              margin-top: $gutter-vertical-micro;
              @include screen-tablet-portrait-and-bigger {
                margin-top: $gutter-vertical-small;
              }
            }
          }
          .events-wrapper {
            display: flex;
            column-gap: $gutter-vertical-big;
            row-gap: $gutter-vertical-big;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            padding: $gutter-vertical-micro*6 0;
            @include screen-tablet-portrait-and-bigger {
              flex-direction: row;
              padding: $gutter-vertical-bigger $gutter-vertical-regular;
              row-gap: $gutter-vertical-bigger;
            }
            @include screen-laptop-and-bigger {
              flex-direction: row;
              padding: $gutter-vertical-bigger $gutter-vertical-micro*8;
            }
            .event-container {
              display: flex;
              flex-direction: column;
              text-decoration: none;
              flex: 0 1 100%;
              @include screen-tablet-portrait-and-bigger {
                flex: 0 1 50%;
              }
              @include screen-laptop-and-bigger {
                flex: 0 1 35%;
              }
              .event-container-text {
                text-align: center;
                height: 100%;
                display: flex;
                flex-direction: column;
                h3 {
                  display: flex;
                  justify-content: center;
                  @include text-style-desktop-xl-medium;
                }
                > * {
                  margin-top: $gutter-vertical-small;
                  @include screen-tablet-portrait-and-bigger {
                    margin-top: $gutter-vertical-regular;
                  }
                }
                span.caption {
                  & + .caption {
                    margin-top: 0;
                  }
                }
                .caption {
                  flex-grow: 1;
                  height: 100%;
                }
              }
              .event-container-image {
                img {
                  background-color: $color-darkblue;
                }
                transition: transform $interaction-timing $ease-out-quart;
                transform-origin: bottom;
              }
              .event-container-text {
                svg {
                  transition: transform $interaction-timing $ease-out-quart;
                  margin-left: $gutter-vertical-micro*.5;
                }
              }
              &:last-of-type {
                margin-bottom: 0;
              }
              transition: color $color-transition-timing linear;
              &:hover {
                color: $color-grey;
                svg {
                  transform: translate($gutter-vertical-micro);
                  path {
                    fill: $color-grey;
                  }
                }
                .event-container-image {
                  transform: scale(1.01);
                }
              }
              .menu--is-active & {
                flex: 0 1 80%;
                @include screen-laptop-and-bigger {
                  flex: 0 1 42%;
                }
              }
            }
            .menu--is-active & {
              padding: $gutter-vertical-bigger $gutter-vertical-regular;
            }
          }
        }
    }
    section.tours-wrapper {
      border-top: 2px $color-darkblue solid;
      padding-top: $gutter-vertical-big;
      @include screen-tablet-landscape-and-bigger {
        padding-top: $gutter-vertical-micro*10;
      }
      .lead-caption-wrapper {
        margin-bottom: $gutter-vertical-micro*6;
        @include screen-tablet-landscape-and-bigger {
          margin-bottom: 0;
        }
      }
    }
}


section.tours-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $gutter-vertical-small;
    margin-bottom: $gutter-vertical-regular;
    @include screen-tablet-landscape-and-bigger {
        margin-bottom: $gutter-vertical-big;
        margin-top: $gutter-vertical-big;
    }
    
	.tour {
        display: flex;
        flex: 0 1 100%;
        flex-flow: column;
        align-items: center;
        padding: 0 0;
        margin-bottom: $gutter-vertical-micro*8;
        span.lead-caption {
            margin-bottom: $gutter-vertical-micro;
        }
        &:last-of-type{
            margin-bottom: 0;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-micro*8;
            }
        }
        @include text-style-desktop-m-medium;
        @include screen-tablet-landscape-and-bigger {
            flex: 0 1 33.33333333%;
            padding: 0 $gutter-vertical-big;
        }
        h4 {
            margin-bottom: $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
                margin-bottom: $gutter-vertical-regular;
            }
        }
        button {
            margin-top: $gutter-vertical-small;
            @include screen-tablet-landscape-and-bigger {
                margin-top: $gutter-vertical-regular;
            }
        }
        .tour-times {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: $gutter-vertical-small;
            column-gap: $gutter-vertical-small;
            color: $color-grey;
        }
	}
}

.event-description {
    span.lead-caption {
      margin-bottom: $gutter-vertical-regular;
    }
    p {
      text-align: center;
      color: $color-yellow;
      @include text-style-desktop-xl-medium;
    }
}
.event-map {
    span.lead-caption {
      margin-bottom: $gutter-vertical-regular;
    }
    .caption {
      @include text-style-desktop-l-medium;
    }
}